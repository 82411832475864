<!-- 报备弹窗 -->
<template>
  <div>
    <el-dialog
      width="30%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'报备':'报备'"
      @update:visible="updateVisible">
      <el-form
        ref="form"
        :model="form"
        label-width="80px">

        <el-row :gutter="15">
          <div class="xuanxianglist">
            <div v-for="(item,index) in list" :key="index" class="kuai">
              <div class="xuanxianglistactive"  :class="{xuanxianglistactive1:index==isShow}" @click="activeItem(item,index)">
                {{item.name}}
              </div>
              <div style="margin-top: 10px;" v-if="item.name == '预约订单' && isShow == 3">
                <el-date-picker
                  value-format="yyyy-MM-dd"
                  style="width: 100%;"
                  v-model="content"
                  type="date"
                  placeholder="选择日期">
                </el-date-picker>
              </div>

              <div style="margin-top: 10px;" v-if="item.name == '救援地错误，需要修改' && isShow == 4">
                <el-button @click="opensiteselection(0)">点击选择</el-button>
              </div>
              <div style="margin-top: 10px;" v-if="item.name == '目的地错误，需要修改' && isShow == 5">
                <el-button @click="opensiteselection(1)">点击选择</el-button>
              </div>
              <div style="margin-top: 10px;" v-if="item.name == '需要辅轮' && isShow == 7">
                <el-radio v-model="content" label="一个">一个</el-radio>
                <el-radio v-model="content" label="二个">二个</el-radio>
                <el-radio v-model="content" label="三个">三个</el-radio>
                <el-radio v-model="content" label="四个">四个</el-radio>
              </div>

              <div style="margin-top: 10px;" v-if="item.name == '订单取消' && isShow == 8">
                <el-input v-model="content" placeholder="请输入取消原因"></el-input>
              </div>

              <div style="margin-top: 10px;" v-if="item.name == '订单空驶' && isShow == 9">
                <el-input v-model="Runempty.content" placeholder="请输入报备内容"></el-input>
                <div style="margin-top: 10px;">
                  <el-upload
                    list-type="picture-card"
                    :on-preview="handlePictureCardPreview"
                    :action="action"
                    :headers="headers"
                    :on-success="successUpload"
                    :file-list="fileList"
                    :on-remove="handleRemove">
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <el-dialog :visible.sync="dialogVisible">
                    <img width="100%" :src="dialogImageUrl" alt="">
                  </el-dialog>
                </div>
              </div>

              <div style="margin-top: 10px;" v-if="item.name == '其他' && isShow == 10">
                <el-input v-model="content.content" placeholder="请输入报备内容"></el-input>
              </div>

            </div>

          </div>
        </el-row>

      </el-form>
      <div slot="footer">
        <el-button
          @click="updateVisible(false)">取消
        </el-button>
        <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="save">确定
        </el-button>
      </div>
    </el-dialog>

    <el-dialog
      center
      title="选择地址"
      :visible.sync="site"
      width="30%"
      :before-close="handleClose">
      <div>
        <!--<div style="display: flex;">-->
        <!--  <el-input clearable v-model="keywords" placeholder="请输入位置"/>-->
        <!--  <el-button style="margin-left: 10px;" @click="onSearch">搜索</el-button>-->
        <!--</div>-->
        <iframe id="mapPage" width="100%" height="700px;" frameborder="0" v-bind:src="keyUrl"></iframe>
      </div>
      <div slot="footer">
        <!--<el-button-->
        <!--  @click="dialogVisible = false">取消-->
        <!--</el-button>-->
        <el-button
          style="margin-left: 20px;"
          type="primary"
          @click="site = false">确定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
//获取高德地图的key 腾讯地图的key
import {Savereport} from "@/api/orderModule";

// 上传配置
// 获取配置
import setting from '@/config/setting'
import {getCookies} from '@/utils/cookies'
import Config from '@/config'
import settingConfig from '@/config/config'



export default {
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      // 上传接口
      action: setting.apiBaseURL + 'common/upload/order',
      // 上传请求头部
      headers:{
        'Authori-Zation' : 'Bearer' + ' ' + getCookies(Config.tokenStoreName)
      },
      dialogImageUrl: '',
      dialogVisible: false,
      fileList:[],

      //选择地址
      site:false,
      //iframe
      keyUrl:'',

      //数据
      list:[
        {
          name:'联系不上客户'
        },
        {
          name:'现场车牌、车架号、车型不符'
        },
        {
          name:'车上有货客户不愿意卸货'
        },
        {
          name:'预约订单'
        },
        {
          name:'救援地错误，需要修改'
        },
        {
          name:'目的地错误，需要修改'
        },
        {
          name:'车在地库'
        },
        {
          name:'需要辅轮'
        },
        {
          name:'订单取消'
        },
        {
          name:'订单空驶'
        },
        {
          name:'其他'
        },
      ],
      isShow:0,

      type:'',
      matter:'',
      content:'',

      Runempty:{
        content:'',
        img:[],
      }

    };
  },
  watch: {

  },
  mounted() {
    // 腾讯地图组件监听
    window.addEventListener(
      'message',
      function (event) {
        // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
        var loc = event.data;
        if (loc && loc.module === 'locationPicker') {
          // 防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
          window.parent.selectAdderss(loc);
        }
      },
      false,
    );
    window.selectAdderss = this.selectAdderss;

    console.log(this.data,'报备数据')
  },
  methods: {
    // 腾讯地图选址组件
    //点击选择地址
    opensiteselection(number){
      this.site = true;
      // 用于判断是起点还是终点
      this.origin = number;
      //打开腾讯地图选址组件
      let keys = settingConfig.Tencentkey;
      this.keyUrl = `https://apis.map.qq.com/tools/locpicker?type=1&key=${keys}&referer=myapp&coordtype=1&sig=ytLgIfdiuGLdxRAl1MZ7qwxPOI3W5K9k`;
    },
    // 选择经纬度
    selectAdderss(data) {
      console.log(data)
      // 救援起点
      if(this.origin == 0){
        // 救援起点的位置
        this.content = data.poiaddress + data.poiname;
        // 救援起点的经度纬度
        // this.form.starting_point_coord[0] = data.latlng.lat;
        // this.form.starting_point_coord[1] = data.latlng.lng;
        // 隐藏选址弹窗组件
        this.site = false;
        // // 赋值给传递的起点经度纬度
        // this.locationorigin[0] = data.latlng.lat;
        // this.locationorigin[1] = data.latlng.lng;
      }else {
        //救援终点位置
        this.content = data.poiaddress + data.poiname;
        // // 救援终点经度纬度
        // this.form.destination_coord[0] = data.latlng.lat;
        // this.form.destination_coord[1] = data.latlng.lng;
        // 隐藏选址弹窗组件
        this.site = false;
        // // 赋值给传递的终点经度纬度
        // this.locationdestination[0] = data.latlng.lat;
        // this.locationdestination[1] = data.latlng.lng;
      }
    },

    handleClose(done) {
      this.selectaddress = false;
    },


    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          // 订单空驶单独配置
          if(this.isShow == 9){
            var data = {
              type: this.type,
              matter: this.matter,
              content: this.Runempty
            };
          }else {
            var data = {
              type: this.type,
              matter: this.matter,
              content: this.content
            };
          }
          Savereport(this.form.id, data).then(res => {
            if(res.code == 200){
              this.$message.success(res.msg)
              this.loading = true;
              this.updateVisible(false);
              this.$emit('done');
              this.loading = false;
            }else {
              this.$message.error(res.msg)
            }
          }).catch(e => {
            this.$message.error(e.msg)
          })
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

    //选择类型
    activeItem(item,index){
      console.log(item);
      console.log(index);
      this.isShow = index;
      if(this.isShow == 3 || this.isShow == 4 || this.isShow == 5 || this.isShow == 7 || this.isShow == 8 || this.isShow == 9 || this.isShow == 10){
        this.type = 'audit'
      }else {
        this.type = 'ordinary'
      }
      this.matter = item.name;
    },

    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    successUpload(file, fileList){
      console.log(file);
      console.log(fileList)
      this.handdialogImageUrl = file.data.urls;
      // 上传成功后的列表
      this.Runempty.img.push(file.data.urls);
    },

  }
}
</script>

<style scoped lang="scss">
.xuanxianglist{

  .kuai{
    margin: 10px;
    //width: 44%;
    //display: inline-block;
  }
  .xuanxianglistactive{
    cursor: pointer;
    background: #ffffff;
    border: 1px solid #e9eaf1;
    border-radius: 6px;
    display: inline-block;
    width: 100%;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .xuanxianglistactive:hover{
    background: #fff4e3;
    border: 1px solid #ffd38e;
    border-radius: 6px;
    color: #FF9B05;
  }
  .xuanxianglistactive1{
    background: #fff4e3;
    border: 1px solid #ffd38e;
    border-radius: 6px;
    color: #FF9B05;
  }
}
</style>
