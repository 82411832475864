<template>
  <div>
    <div v-if="showEdit === false">
      <div class="title" style="margin-bottom: 0;">
        <div class="title_t">
          <span>订单详情</span>
        </div>
        <div class="title_r" @click="returnPage">
          <span>返回</span>
        </div>
      </div>

      <!--选项-->
      <div class="xuanxiang">
        <div class="xuanxiang_k" v-for="(item,index) in activeList" :key="index" :class="{xuanxiang_k1:index==isShow}" @click="xuanxiangclick(item,index)">
          <img :src="item.img" alt="">
          <span>{{item.name}}</span>
        </div>
      </div>

      <!--基本信息 模块-->
      <div v-if="isShow == 0">
        <el-form ref="form" :model="form" label-width="100px">
          <!-- 基本信息选项 -->
          <el-card shadow="never">
            <el-row>
              <!--第一行-->
              <el-col :span="24" style="margin-top: 10px;">
                <div class="info">
                  <div class="info_active" @click="goAnchor('#zhuyishixiang')">
                    <img src="@/assets/images/home/order/icon1.png" alt="">
                    <div class="text">注意事项</div>
                  </div>
                </div>
                <div class="info">
                  <div class="info_active" @click="goAnchor('#jibenxinxi')">
                    <img src="@/assets/images/home/order/icon2.png" alt="">
                    <div class="text">基本信息</div>
                  </div>
                </div>
                <div class="info">
                  <div class="info_active" @click="goAnchor('#jiedanxinxi')">
                    <img src="@/assets/images/home/order/icon3.png" alt="">
                    <div class="text">接单信息</div>
                  </div>
                </div>
                <div class="info">
                  <div class="info_active" @click="goAnchor('#jiesuanxinxi')">
                    <img src="@/assets/images/home/order/icon4.png" alt="">
                    <div class="text">结算信息</div>
                  </div>
                </div>
                <div class="info">
                  <div class="info_active" @click="goAnchor('#kaohexinxi')">
                    <img src="@/assets/images/home/order/icon5.png" alt="">
                    <div class="text">考核信息</div>
                  </div>
                </div>
                <div class="info">
                  <div class="info_active" @click="goAnchor('#gonglixinxi')">
                    <img src="@/assets/images/home/order/icon6.png" alt="">
                    <div class="text">公里信息</div>
                  </div>
                </div>
                <div class="info">
                  <div class="info_active" @click="goAnchor('#huifangxinxi')">
                    <img src="@/assets/images/home/order/icon7.png" alt="">
                    <div class="text">回访信息 </div>
                  </div>
                </div>
                <div class="info">
                  <div class="info_active" @click="goAnchor('#tonghuaxinxi')">
                    <img src="@/assets/images/home/order/icon8.png" alt="">
                    <div class="text">通话信息</div>
                  </div>
                </div>
                <div class="info">
                  <div class="info_active" @click="goAnchor('#zhaopianshenhe')">
                    <img src="@/assets/images/home/order/icon9.png" alt="">
                    <div class="text">照片审核 </div>
                  </div>
                </div>
                <div class="info" style="width: 10% !important;">
                  <div class="info_active" @click="goAnchor('#pandanshijianzhou')">
                    <img src="@/assets/images/home/order/icon10.png" alt="">
                    <div class="text">派单时间轴</div>
                  </div>
                </div>

              </el-col>
              <!--第二行-->
              <el-col :span="24" style="margin-top: 20px;">
                <div class="info">
                  <div class="info_active" @click="goAnchor('#gongdanzhaopian')">
                    <img src="@/assets/images/home/order/icon11.png" alt="">
                    <div class="text">工单照片</div>
                  </div>
                </div>
                <div class="info">
                  <div class="info_active" @click="goAnchor('#dingdanbeizhu')">
                    <img src="@/assets/images/home/order/icon12.png" alt="">
                    <div class="text">订单备注</div>
                  </div>
                </div>
                <div class="info">
                  <div class="info_active" @click="goAnchor('#jishibaobei')">
                    <img src="@/assets/images/home/order/icon13.png" alt="">
                    <div class="text">司机报备</div>
                  </div>
                </div>
                <div class="info">
                  <div class="info_active" @click="goAnchor('#guanliandingdan')">
                    <img src="@/assets/images/home/order/icon14.png" alt="">
                    <div class="text">关联订单</div>
                  </div>
                </div>
                <div class="info">
                  <div class="info_active" @click="goAnchor('#zidingdan')">
                    <img src="@/assets/images/home/order/icon15.png" alt="">
                    <div class="text">子订单</div>
                  </div>
                </div>
                <div class="info">
                  <div class="info_active" @click="goAnchor('#genjinjilu')">
                    <img src="@/assets/images/home/order/icon16.png" alt="">
                    <div class="text">跟进记录</div>
                  </div>
                </div>
                <div class="info">
                  <div class="info_active" @click="goAnchor('#duanxindianhu')">
                    <img src="@/assets/images/home/order/icon17.png" alt="">
                    <div class="text">短信电话</div>
                  </div>
                </div>
                <div class="info">
                  <div class="info_active" @click="goAnchor('#chezhupingjia')">
                    <img src="@/assets/images/home/order/icon18.png" alt="">
                    <div class="text">客户评价</div>
                  </div>
                </div>
                <div class="info">
                  <div class="info_active" @click="goAnchor('#cuidan')">
                    <img src="@/assets/images/home/order/icon19.png" alt="">
                    <div class="text">催单</div>
                  </div>
                </div>
                <div class="info">
                  <div class="info_active" @click="goAnchor('#xiugaijilu')">
                    <img src="@/assets/images/home/order/icon20.png" alt="">
                    <div class="text">修改记录</div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-card>

          <!-- 功能主体 -->
          <el-card shadow="never">
            <!--功能选项按钮-->
            <el-row :gutter="15" style="    display: flex;align-items: center;">
              <!--左侧-->
              <el-col :span="18">
                <div class="FunctionOptions" @click="ToExamine()">
                  <img src="@/assets/images/home/order/active2.png" alt="">
                  <span>审核</span>
                </div>
                <div class="FunctionOptions" @click="Reporting()">
                  <img src="@/assets/images/home/order/active2.png" alt="">
                  <span>报备</span>
                </div>
                <div class="FunctionOptions" @click="ReturnVisit()">
                  <img src="@/assets/images/home/order/active3.png" alt="">
                  <span>回访</span>
                </div>
                <div class="FunctionOptions" @click="ToReviewACase()">
                  <img src="@/assets/images/home/order/active4.png" alt="">
                  <span>复审</span>
                </div>
                <div class="FunctionOptions" @click="Reassignment()">
                  <img src="@/assets/images/home/order/active5.png" alt="">
                  <span>改派</span>
                </div>
                <div class="FunctionOptions" @click="biaoji()">
                  <img src="@/assets/images/home/order/active6.png" alt="">
                  <span>标记</span>
                </div>
                <div class="FunctionOptions" @click="TakeBack()">
                  <img src="@/assets/images/home/order/active7.png" alt="">
                  <span>收回</span>
                </div>
                <div class="FunctionOptions" @click="Reporting()">
                  <img src="@/assets/images/home/order/active8.png" alt="">
                  <span>取消</span>
                </div>
                <div class="FunctionOptions" @click="Reporting()">
                  <img src="@/assets/images/home/order/active9.png" alt="">
                  <span>空驶</span>
                </div>
              </el-col>

              <!--右侧-->
              <el-col :span="6" style="display: flex;justify-content: space-between;">
                <div style="cursor: pointer">
                  <img style="width: 15px;height: 15px;vertical-align: middle" src="@/assets/images/home/order/lianjie.png" alt="">
                  <span style="font-size: 14px;font-weight: 400;text-align: left;color: #fea837;vertical-align: middle; margin-left: 5px;">复制用户H5链接</span>
                </div>
                <div style="margin-left: 20px; cursor: pointer;" @click="CustomerScript()">
                  <img style="width: 15px;height: 15px;vertical-align: middle" src="@/assets/images/home/order/huashu.png" alt="">
                  <span style="font-size: 14px;font-weight: 400;text-align: left;color: #5976E1;vertical-align: middle; margin-left: 5px;">困境救援话术</span>
                </div>
              </el-col>
            </el-row>

            <!-- 消息 -->
            <el-row :gutter="15" style="margin-top: 20px;">
              <el-col :span="24">
                <div class="xiaoxi">
                  <img src="@/assets/images/home/order/xiaoxi.png" alt="">
                  <span style="margin-left: 20px;">2023-09-0800:00:12</span>
                  <span style="margin-left: 20px;">到达超时</span>
                  <span style="margin: 20px;">责任人：张三三</span>
                  <span style="color: #34DC73;">待处理</span>
                </div>
              </el-col>
            </el-row>

            <!--  注意事项-->
            <div class="ageing" style="margin-top: 30px;" id="zhuyishixiang">
              <div class="Datalist">
                <div class="Datalist_l">
                  <div class="Datalist_icon"></div>
                  <div class="Datalist_text">
                    注意事项
                  </div>
                </div>
              </div>
              <!-- H5链接说明文字 -->
              <div style="margin-top: 15px;">
                <el-input
                    clearable
                    :autosize="{ minRows: 2, maxRows: 5}"
                    type="textarea"
                    :rows="2"
                    placeholder="请输入注意事项"
                    v-model="textarea">
                </el-input>
              </div>
            </div>

            <!--基本信息-->
            <div class="ageing" style="margin-top: 20px;" id="jibenxinxi">
              <div class="Datalist">
                <div class="Datalist_l">
                  <div class="Datalist_icon"></div>
                  <div class="Datalist_text">
                    基本信息
                  </div>
                  <div style="margin-left: 20px;" v-if="isEdit == true">
                    <el-radio v-model="form.radio" label="1">立即救援</el-radio>
                    <el-radio v-model="form.radio" label="2">预约救援</el-radio>
                  </div>
                </div>
                <div>
                  <div class="custom" style="margin-left: 20px">
                    <el-button class="custom-button" v-if="isEdit == false" @click="Edit()">
                      <!--el-icon-devops 是自己命名的-->
                      <i class="el-icon-bianji" />
                      <span style="vertical-align: middle">编辑</span>
                    </el-button>
                    <div v-else>
                      <el-button class="custom-button">
                        <span style="vertical-align: middle" @click="infoConfirm()">确认</span>
                      </el-button>
                      <el-button @click="infoCancel()">取消</el-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="ageing_b">
                <el-row style="margin: 15px;">
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">车牌号：</span>
                    <span class="ageing_b_text_r" v-if="isEdit == false">{{form.basics.license_plate}}</span>
                    <el-input
                        style="width: 150px;"
                        v-else
                        clearable
                        :maxlength="20"
                        v-model="form.basics.license_plate"
                        placeholder="请输入车牌号"/>
                  </el-col>
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">品牌型号：</span>
                    <span class="ageing_b_text_r" v-if="isEdit == false">{{form.basics.car_brand}}</span>
                    <el-input
                        style="width: 150px;"
                        v-else
                        clearable
                        :maxlength="20"
                        v-model="form.basics.car_brand"
                        placeholder="请输入品牌型号"/>
                  </el-col>
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">汽车类型：</span>
                    <span class="ageing_b_text_r" v-if="isEdit == false">{{form.basics.car_type}}</span>
                    <el-select
                        v-else
                        clearable
                        v-model="form.basics.car_type"
                        placeholder="请选择"
                        class="ele-fluid">
                      <el-option
                          v-for="(item) in dict.type.automobile_type"
                          :label="item.label"
                          :value="parseInt(item.value)"
                      />
                    </el-select>
                  </el-col>
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">车架号：</span>
                    <span class="ageing_b_text_r" v-if="isEdit == false">{{form.basics.vin}} </span>
                    <el-input
                        style="width: 150px;"
                        v-else
                        clearable
                        :maxlength="20"
                        v-model="form.basics.vin"
                        placeholder="请输入车架号"/>
                  </el-col>
                </el-row>
                <el-row style="margin: 15px;">
                  <el-col :span="6" class="buju">
                    <span class="ageing_b_text_l">申请救援地：</span>
                    <span class="ageing_b_text_r">{{form.basics.starting_point}}</span>
                  </el-col>
                  <el-col :span="6" class="buju">
                    <span class="ageing_b_text_l">申请目的地：</span>
                    <span class="ageing_b_text_r">{{form.basics.destination}}</span>
                  </el-col>
                  <el-col :span="6" class="buju">
                    <span class="ageing_b_text_l">实际救援地：</span>
                    <span class="ageing_b_text_r" v-if="isEdit == false">{{form.basics.starting_point}}</span>
                    <el-button v-else @click="opensiteselection(0)">点击选择</el-button>
                  </el-col>
                  <el-col :span="6" class="buju">
                    <span class="ageing_b_text_l">实际目的地：</span>
                    <span class="ageing_b_text_r" v-if="isEdit == false">{{form.basics.destination}}</span>
                    <el-button v-else @click="opensiteselection(1)">点击选择</el-button>
                  </el-col>
                </el-row>
              </div>
            </div>

            <!--接单信息-->
            <div class="ageing" style="margin-top: 20px;" id="jiedanxinxi">
              <div class="Datalist">
                <div class="Datalist_l">
                  <div class="Datalist_icon"></div>
                  <div class="Datalist_text">
                    接单信息
                  </div>
                </div>
                <div>
                  <div class="custom" style="margin-left: 20px">
                    <el-button class="custom-button" v-if="orderReceivingisEdit == false" @click="orderReceiving()">
                      <!--el-icon-devops 是自己命名的-->
                      <i class="el-icon-bianji" />
                      <span style="vertical-align: middle">编辑</span>
                    </el-button>
                    <div v-else>
                      <el-button class="custom-button" @click="orderReceivingConfirm()">
                        <span style="vertical-align: middle">确认</span>
                      </el-button>
                      <el-button @click="orderReceivingCancel()">取消</el-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="ageing_b" style="padding-left: 20px; padding-right: 20px;">
                <el-row style="margin: 15px;">
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">客户：</span>
                    <span class="ageing_b_text_r" v-if="orderReceivingisEdit == false">{{form.joinInfo.owners}}</span>
                    <el-input
                        v-else
                        clearable
                        :maxlength="20"
                        v-model="form.joinInfo.owners"
                        placeholder="请输入客户姓名"/>
                  </el-col>
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">客户号码：</span>
                    <span class="ageing_b_text_r" style="vertical-align: middle" v-if="orderReceivingisEdit == false">{{form.joinInfo.owners_phone}}</span>
                    <img style="width: 18px;height: 18px; vertical-align: middle;margin-top: 2px; margin-left: 5px;" src="@/assets/images/home/order/phone.png" alt="" v-if="orderReceivingisEdit == false">
                    <el-input
                        v-else
                        clearable
                        :maxlength="20"
                        v-model="form.joinInfo.owners_phone"
                        placeholder="请输入客户号码"/>
                  </el-col>
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">卸车联系人：</span>
                    <span class="ageing_b_text_r" v-if="orderReceivingisEdit == false">{{form.joinInfo.unload_name}}</span>
                    <el-input
                        style="width: 150px;"
                        v-else
                        clearable
                        :maxlength="20"
                        v-model="form.joinInfo.unload_name"
                        placeholder="请输入卸车联系人"/>
                  </el-col>
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">卸车联系人号码：</span>
                    <span class="ageing_b_text_r" style="vertical-align: middle" v-if="orderReceivingisEdit == false">{{form.joinInfo.unload_phone}}</span>
                    <img style="width: 18px;height: 18px; vertical-align: middle;margin-top: 2px; margin-left: 5px;" src="@/assets/images/home/order/phone.png" alt="" v-if="orderReceivingisEdit == false">
                    <el-input
                        style="width: 100px;"
                        v-else
                        clearable
                        :maxlength="20"
                        v-model="form.joinInfo.unload_phone"
                        placeholder="请输入卸车联系人号码"/>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="6" class="buju" style="display: flex;align-items: center;">
                    <span class="ageing_b_text_l">服务项目：</span>
                    <span class="ageing_b_text_r" v-if="orderReceivingisEdit == false">{{form.joinInfo.items}}</span>
                    <el-select
                        style="width: 150px !important;"
                        v-else
                        clearable
                        v-model="form.joinInfo.items"
                        placeholder="请选择"
                        class="ele-fluid">
                      <el-option v-for="(item) in ServicesAvailable" :label="item.label" :value="item.serial_number"/>
                    </el-select>
                  </el-col>
                  <el-col :span="6" class="buju" style="display: flex;align-items: center;">
                    <span class="ageing_b_text_l">增补服务项目：</span>
                    <span class="ageing_b_text_r" v-if="orderReceivingisEdit == false">{{form.joinInfo.supplemental_items}}</span>
                    <el-select
                        style="width: 150px !important;"
                        v-else
                        clearable
                        v-model="form.joinInfo.supplemental_items"
                        placeholder="请选择"
                        class="ele-fluid">
                      <el-option v-for="(item) in ServicesAvailable" :label="item.label" :value="item.serial_number"/>
                    </el-select>
                  </el-col>
                  <el-col :span="6" class="buju">
                    <span class="ageing_b_text_l">系统受理人：</span>
                    <span class="ageing_b_text_r">{{form.joinInfo.acceptor_nickname}}</span>
                  </el-col>
                  <el-col :span="6" class="buju">
                    <span class="ageing_b_text_l">派单人：</span>
                    <span class="ageing_b_text_r">{{form.joinInfo.send_nickname}}</span>
                  </el-col>
                </el-row>
                <el-row style="margin-top: 10px;">
                  <el-col :span="6" class="buju">
                    <span class="ageing_b_text_l">跟进人：</span>
                    <span class="ageing_b_text_r">{{form.joinInfo.loss_assessor_name}}</span>
                  </el-col>
                  <el-col :span="6" class="buju">
                    <span class="ageing_b_text_l">回访人：</span>
                    <span class="ageing_b_text_r">{{form.joinInfo.visit_nickname}}</span>
                  </el-col>
                  <el-col :span="6" class="buju">
                    <span class="ageing_b_text_l">服务商区域负责人：</span>
                    <span class="ageing_b_text_r">{{form.joinInfo.principal_name}}</span>
                  </el-col>
                </el-row>

                <div class="fuwuinfo">
                  <el-row :gutter="15" style="display: flex;align-items: center;">
                    <el-col :span="8" style="margin-top: 2px;">
                      <img style="vertical-align: middle; width: 17px; height: 17px;" src="@/assets/images/home/order/fuwuyuan.png" alt="">
                      <span style="font-size: 16px;font-weight: 400;text-align: right;color: #868792; margin-left: 5px; vertical-align: middle;">服务人员：</span>
                      <span style="font-size: 16px;font-weight: 400;text-align: right;color: #050505; vertical-align: middle;" v-if="orderReceivingisEdit == false">刘文明</span>
                      <el-button v-else>弹出选择自营/外协/共享司机</el-button>
                    </el-col>
                    <el-col :span="6" style="margin-top: 2px;">
                      <img style="vertical-align: middle; width: 17px; height: 17px;" src="@/assets/images/home/order/dianhua.png" alt="">
                      <span style="font-size: 16px;font-weight: 400;text-align: right;color: #868792; margin-left: 5px; vertical-align: middle;">手机号：</span>
                      <span style="font-size: 16px;font-weight: 400;text-align: right;color: #050505; vertical-align: middle;">13800000001</span>
                      <img style="vertical-align: middle; width: 18px;height: 18px; margin-left: 5px;" src="@/assets/images/home/order/phone.png" alt="">
                    </el-col>
                    <el-col :span="12">
                      <img style="vertical-align: middle; width: 17px; height: 17px;" src="@/assets/images/home/order/fuwushang.png" alt="">
                      <span style="font-size: 16px;font-weight: 400;text-align: right;color: #868792; margin-left: 5px; vertical-align: middle;">服务商：</span>
                      <span style="font-size: 16px;font-weight: 400;text-align: right;color: #050505; vertical-align: middle;" v-if="orderReceivingisEdit == false">新华保险股份有限公司河南省郑州市分公司</span>
                      <span style="background: #ff9b05;border-radius: 6px; width: 60px; text-align: center; padding-top: 8px; padding-bottom: 8px;display: inline-block;color: #fff;margin-left: 5px;" v-if="orderReceivingisEdit == false">修改</span>
                      <el-button v-else>点击弹出选择服务商</el-button>
                    </el-col>
                  </el-row>
                  <el-row :gutter="15" style="margin-top: 10px; margin-bottom: 20px;">
                    <el-col :span="6" class="buju" style="display: flex;align-items: center;">
                      <span class="ageing_b_text_l">到达现场里程：</span>
                      <span class="ageing_b_text_r" v-if="orderReceivingisEdit == false">15</span>
                      <el-input
                          style="width: 50px;"
                          v-else
                          clearable
                          :maxlength="20"
                          v-model="form.nickname"
                          placeholder="请输入"/>
                      <span class="ageing_b_text_r">公里</span>
                    </el-col>
                    <el-col :span="6" class="buju" style="display: flex;align-items: center;">
                      <span class="ageing_b_text_l">背程里程：</span>
                      <span class="ageing_b_text_r" v-if="orderReceivingisEdit == false">15</span>
                      <el-input
                          style="width: 50px;"
                          v-else
                          clearable
                          :maxlength="20"
                          v-model="form.nickname"
                          placeholder="请输入"/>
                      <span class="ageing_b_text_r">公里</span>
                    </el-col>
                    <el-col :span="6" class="buju">
                      <span class="ageing_b_text_l">行驶总里程：</span>
                      <span class="ageing_b_text_r">15公里</span>
                    </el-col>
                    <el-col :span="6" class="buju" style="display: flex;align-items: center;">
                      <span class="ageing_b_text_l">免拖公里数：</span>
                      <span class="ageing_b_text_r" v-if="orderReceivingisEdit == false">50</span>
                      <el-input
                          style="width: 50px;"
                          v-else
                          clearable
                          :maxlength="20"
                          v-model="form.nickname"
                          placeholder="请输入"/>
                      <span class="ageing_b_text_r">公里</span>
                    </el-col>
                  </el-row>
                  <el-row :gutter="15" style="margin-bottom: 20px;">
                    <el-col :span="6" class="buju">
                      <span class="ageing_b_text_l">是否塔电成功：</span>
                      <span class="ageing_b_text_r">是</span>
                    </el-col>
                    <el-col :span="6" class="buju">
                      <span class="ageing_b_text_l">是否更换电源：</span>
                      <span class="ageing_b_text_r">是</span>
                    </el-col>
                    <el-col :span="6" class="buju">
                      <span class="ageing_b_text_l">电池识别码：</span>
                      <span class="ageing_b_text_r">41520200120</span>
                    </el-col>
                    <el-col :span="6" class="buju">
                      <span class="ageing_b_text_l">换电瓶费用：</span>
                      <span class="ageing_b_text_r">40.5</span>
                    </el-col>
                  </el-row>
                  <el-row :gutter="15" style="margin-top: 10px;">
                    <el-col :span="6" class="buju">
                      <span class="ageing_b_text_l">计价标准：</span>
                      <span class="ageing_b_text_r">是</span>
                    </el-col>
                    <el-col :span="6" class="buju" style="display: flex;align-items: center;">
                      <span class="ageing_b_text_l">结算方式：</span>
                      <span class="ageing_b_text_r" style="color: #FF9B05;" v-if="orderReceivingisEdit == false">月结</span>
                      <el-select
                          style="width: 150px !important;"
                          v-else
                          clearable
                          v-model="form.status"
                          placeholder="选择月结/现金"
                          class="ele-fluid">
                        <el-option label="月结" value="1"/>
                        <el-option label="现金" value="2"/>
                      </el-select>
                    </el-col>
                    <el-col :span="6" class="buju" style="display: flex;align-items: center;">
                      <span class="ageing_b_text_l">计价方式：</span>
                      <span class="ageing_b_text_r" v-if="orderReceivingisEdit == false">系统计价</span>
                      <el-select
                          style="width: 150px !important;"
                          v-else
                          clearable
                          v-model="form.status"
                          placeholder="选择系统计价/仪价"
                          class="ele-fluid">
                        <el-option label="系统计价" value="1"/>
                        <el-option label="仪价" value="2"/>
                      </el-select>
                    </el-col>
                  </el-row>
                  <el-row :gutter="15" v-if="form.status == 1 && orderReceivingisEdit == true">
                    <el-col :span="6">
                      <span class="ageing_b_text_l">结算金额：</span>
                      <el-input
                          style="width: 50px;"
                          clearable
                          :maxlength="20"
                          v-model="form.nickname"
                          placeholder="请输入"/>
                      <span class="ageing_b_text_r">元</span>
                    </el-col>
                  </el-row>
                  <el-row :gutter="15" v-if="form.status == 2 && orderReceivingisEdit == true" style="margin-top: 15px;">
                    <el-col :span="6">
                      <span class="ageing_b_text_l">结算金额：</span>
                      <el-input
                          style="width: 50px;"
                          clearable
                          :maxlength="20"
                          v-model="form.nickname"
                          placeholder="请输入"/>
                      <span class="ageing_b_text_r">元</span>
                    </el-col>
                    <el-col :span="6">
                      <span class="ageing_b_text_l">议价金额：</span>
                      <el-input
                          style="width: 50px;"
                          clearable
                          :maxlength="20"
                          v-model="form.nickname"
                          placeholder="请输入"/>
                      <span class="ageing_b_text_r">元</span>
                    </el-col>
                    <el-col :span="6">
                      <span class="ageing_b_text_l">合计金额：</span>
                      <el-input
                          style="width: 50px;"
                          clearable
                          :maxlength="20"
                          v-model="form.nickname"
                          placeholder="请输入"/>
                      <span class="ageing_b_text_r">元</span>
                    </el-col>
                  </el-row>
                  <el-row :gutter="15" v-if="form.status == 2 && orderReceivingisEdit == true" style="margin-top: 15px;">
                    <el-col :span="12">
                      <div style="display: flex;align-items: center;">
                        <span class="ageing_b_text_l">议价原因：</span>
                        <el-input
                            clearable
                            :maxlength="20"
                            v-model="form.nickname"
                            placeholder="请输入"/>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row style="color: #ff9b05;margin-top: 10px;">
                    <span style="margin-left: 50px;">* 议价时展示,自动赋值到下边收款</span>
                  </el-row>
                </div>

                <div class="fuwuinfo_b" style="margin-top: 20px;">
                  <el-row style="display: flex;align-items: center;">
                    <el-col :span="6" style="margin-top: 2px;">
                      <span class="ageing_b_text_l">订单属性：</span>
                      <span class="ageing_b_text_r">份额订单/聚合订单</span>
                    </el-col>
                    <el-col :span="10">
                      <span style="font-size: 16px;font-weight: 400;text-align: right;color: #868792; margin-left: 5px; vertical-align: middle;">服务商：</span>
                      <span style="font-size: 16px;font-weight: 400;text-align: right;color: #050505; vertical-align: middle;">新华保险股份有限公司河南省郑州市分公司</span>
                      <span style="background: #5976E1;border-radius: 6px; width: 60px; text-align: center; padding-top: 8px; padding-bottom: 8px;display: inline-block;color: #fff;margin-left: 5px;cursor: pointer;" @click="TakeBack()">收回</span>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>

            <!--结算信息-->
            <div class="ageing" style="margin-top: 20px;" id="jiesuanxinxi">
              <div class="Datalist">
                <div class="Datalist_l">
                  <div class="Datalist_icon"></div>
                  <div class="Datalist_text">
                    订单结算信息
                  </div>
                </div>
                <div>
                  <div class="custom" style="margin-left: 20px">
                    <el-button class="custom-button" v-if="settlementEdit == false" @click="settlement">
                      <!--el-icon-devops 是自己命名的-->
                      <i class="el-icon-bianji" />
                      <span style="vertical-align: middle">编辑</span>
                    </el-button>
                    <div v-else>
                      <el-button class="custom-button">
                        <span style="vertical-align: middle" @click="settlementConfirm()">确认</span>
                      </el-button>
                      <el-button @click="settlementCancel()">取消</el-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="ageing_b">
                <el-row style="margin: 15px;">
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">保单号：</span>
                    <span class="ageing_b_text_r" v-if="settlementEdit == false">{{form.settlementInformation.policy_number}}</span>
                    <el-input
                        v-else
                        clearable
                        :maxlength="20"
                        v-model="form.settlementInformation.policy_number"
                        placeholder="请输入保单号"/>
                  </el-col>
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">事故号码：</span>
                    <span class="ageing_b_text_r" v-if="settlementEdit == false">{{form.settlementInformation.case_number}}</span>
                    <el-input
                        v-else
                        clearable
                        :maxlength="20"
                        v-model="form.settlementInformation.case_number"
                        placeholder="请输入事故号码"/>
                  </el-col>
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">收款金额：</span>
                    <span class="ageing_b_text_r" v-if="settlementEdit == false">{{form.settlementInformation.receivable_amount}}</span>
                    <el-input
                        v-else
                        clearable
                        :maxlength="20"
                        v-model="form.settlementInformation.receivable_amount"
                        placeholder="请输入收款金额"/>
                  </el-col>
                  <el-col :span="6" style="text-align: center;display: flex;align-items: center;" class="buju">
                    <span class="ageing_b_text_l">收款状态：</span>
                    <span style="width: 12px;height: 12px;background: #2ee822;border-radius: 50%;box-shadow: 0px 3px 6px 0px rgba(46,232,34,0.60); display: inline-block; " v-if="settlementEdit == false"></span>
                    <span class="ageing_b_text_r" style="color: #2ee822;" v-if="settlementEdit == false">{{form.settlementInformation.paid}}</span>
                    <el-select
                        style="width: 150px !important;"
                        v-else
                        clearable
                        v-model="form.settlementInformation.paid"
                        placeholder="请选择"
                        class="ele-fluid">
                      <el-option label="未收款" value="1"/>
                      <el-option label="已收款" value="2"/>
                    </el-select>
                  </el-col>
                </el-row>
                <el-row style="margin: 15px;">
                  <el-col :span="6" class="buju" style="display: flex;align-items: center;">
                    <span class="ageing_b_text_l">客户：</span>
                    <span class="ageing_b_text_r" v-if="settlementEdit == false">{{form.settlementInformation.nickname}}</span>
                    <el-input
                        v-else
                        clearable
                        :maxlength="20"
                        v-model="form.settlementInformation.nickname"
                        placeholder="请输入客户"/>
                  </el-col>
                  <el-col :span="6" class="buju">
                    <span class="ageing_b_text_l">修理厂：</span>
                    <span class="ageing_b_text_r">{{form.settlementInformation.repair_name}}</span>
                  </el-col>
                  <el-col :span="6" class="buju">
                    <span class="ageing_b_text_l">支付金额：</span>
                    <span class="ageing_b_text_r" style="color: #FF3705;">{{form.settlementInformation.pay_price}}(未支付)</span>
                  </el-col>
                  <el-col :span="6" class="buju">
                    <span class="ageing_b_text_l">系统金额：</span>
                    <span class="ageing_b_text_r">{{form.settlementInformation.receivable_amount}}</span>
                  </el-col>
                </el-row>
                <el-row style="margin: 15px;">
                  <el-col :span="6" class="buju" style="display: flex;align-items: center;">
                    <span class="ageing_b_text_l">议价金额：</span>
                    <span class="ageing_b_text_r" v-if="settlementEdit == false">{{form.settlementInformation.bargain_price}}</span>
                    <el-input
                        v-else
                        clearable
                        :maxlength="20"
                        v-model="form.settlementInformation.bargain_price"
                        placeholder="请输入"/>
                  </el-col>
                  <el-col :span="6" class="buju">
                    <span class="ageing_b_text_l">支付平台：</span>
                    <span class="ageing_b_text_r">{{form.settlementInformation.mode_of_payment}}</span>
                  </el-col>
                  <el-col :span="6" class="buju">
                    <span class="ageing_b_text_l">支付流水：</span>
                    <span class="ageing_b_text_r" style="color: #FF3705;">{{form.settlementInformation.trade_no}}</span>
                  </el-col>
                </el-row>
              </div>
            </div>

            <!--服务结算信息-->
            <div class="ageing" style="margin-top: 20px;" id="jiedanxinxi">
              <div class="Datalist">
                <div class="Datalist_l">
                  <div class="Datalist_icon"></div>
                  <div class="Datalist_text">
                    服务结算信息
                  </div>
                </div>
                <div>
                  <div class="custom" style="margin-left: 20px">
                    <!--<el-button class="custom-button" v-if="orderReceivingisEdit == false">-->
                    <!--  &lt;!&ndash;el-icon-devops 是自己命名的&ndash;&gt;-->
                    <!--  <i class="el-icon-bianji" />-->
                    <!--  <span style="vertical-align: middle" @click="orderReceiving()">编辑</span>-->
                    <!--</el-button>-->
                    <!--<div v-else>-->
                    <!--  <el-button class="custom-button">-->
                    <!--    <span style="vertical-align: middle" @click="orderReceivingConfirm()">确认</span>-->
                    <!--  </el-button>-->
                    <!--  <el-button @click="orderReceivingCancel()">取消</el-button>-->
                    <!--</div>-->
                  </div>
                </div>
              </div>
              <div class="ageing_b" style="padding-left: 20px; padding-right: 20px;">
                <div class="fuwuinfo" style="margin-top: 0;">
                  <el-row :gutter="15" style="margin-bottom: 20px;">
                    <el-col :span="6" class="buju">
                      <span class="ageing_b_text_l">结算单位：</span>
                      <span class="ageing_b_text_r">{{form.facilitatorSettlementInformation.company_name}}</span>
                    </el-col>
                  </el-row>
                  <el-row :gutter="15" style="margin-bottom: 20px;">
                    <el-col :span="6" class="buju">
                      <span class="ageing_b_text_l">结算金额：</span>
                      <span class="ageing_b_text_r">{{form.facilitatorSettlementInformation.pay_price}}</span>
                    </el-col>
                    <el-col :span="6" class="buju">
                      <span class="ageing_b_text_l">订单补扣款项：</span>
                      <span class="ageing_b_text_r">
                      <span v-for="(item,index) in form.facilitatorSettlementInformation.thread_log">
                        {{item}}
                      </span>
                    </span>
                    </el-col>
                  </el-row>
                  <el-row :gutter="15" style="margin-bottom: 20px;">
                    <el-col :span="6" class="buju">
                      <span class="ageing_b_text_l">订单补扣款金额：</span>
                      <span class="ageing_b_text_r">{{form.facilitatorSettlementInformation.repair_thread_price}}</span>
                    </el-col>
                  </el-row>
                  <el-row :gutter="15" style="margin-bottom: 20px;">
                    <el-col :span="6" class="buju">
                      <span class="ageing_b_text_l">议价金额：</span>
                      <span class="ageing_b_text_r">{{form.facilitatorSettlementInformation.bargain_price}}</span>
                    </el-col>
                    <el-col :span="6" class="buju">
                      <span class="ageing_b_text_l">议价原因：</span>
                      <span class="ageing_b_text_r">{{form.facilitatorSettlementInformation.bargain_reason}}</span>
                    </el-col>
                  </el-row>
                  <el-row :gutter="15" style="margin-bottom: 20px;">
                    <el-col :span="6" class="buju">
                      <span class="ageing_b_text_l">结算状态：</span>
                      <span class="ageing_b_text_r">{{form.facilitatorSettlementInformation.settle_accounts_status}}</span>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>

            <!--考核信息-->
            <div class="ageing" style="margin-top: 20px;" id="kaohexinxi">
              <div class="Datalist">
                <div class="Datalist_l">
                  <div class="Datalist_icon"></div>
                  <div class="Datalist_text">
                    考核信息
                  </div>
                </div>
                <div>
                  <!--<div class="custom" style="margin-left: 20px">-->
                  <!--  <el-button class="custom-button">-->
                  <!--    &lt;!&ndash;el-icon-devops 是自己命名的&ndash;&gt;-->
                  <!--    <i class="el-icon-bianji" />-->
                  <!--    <span style="vertical-align: middle">编辑</span>-->
                  <!--  </el-button>-->
                  <!--</div>-->
                </div>
              </div>
              <div class="ageing_b">
                <el-row style="margin: 15px;">
                  <el-col :span="4" style="text-align: center" class="buju">
                    <img src="@/assets/images/home/order/chenggong.png" alt="" style="width: 24px;height: 24px;vertical-align: middle;">
                    <span style="font-size: 16px;font-weight: 400;text-align: right;color: #050505; vertical-align: middle; margin-left: 8px;">及时响应  2分12秒</span>
                  </el-col>
                  <el-col :span="4" style="text-align: center" class="buju">
                    <img src="@/assets/images/home/order/chenggong.png" alt="" style="width: 24px;height: 24px;vertical-align: middle;">
                    <span style="font-size: 16px;font-weight: 400;text-align: right;color: #050505; vertical-align: middle; margin-left: 8px;">及时接单 1分24秒</span>
                  </el-col>
                  <el-col :span="4" style="text-align: center" class="buju">
                    <img src="@/assets/images/home/order/shibai.png" alt="" style="width: 24px;height: 24px;vertical-align: middle;">
                    <span style="font-size: 16px;font-weight: 400;text-align: right;color: #050505; vertical-align: middle; margin-left: 8px;">及时响应  2分12秒</span>
                  </el-col>
                  <el-col :span="4" style="text-align: center" class="buju">
                    <img src="@/assets/images/home/order/chenggong.png" alt="" style="width: 24px;height: 24px;vertical-align: middle;">
                    <span style="font-size: 16px;font-weight: 400;text-align: right;color: #050505; vertical-align: middle; margin-left: 8px;">及时响应  2分12秒</span>
                  </el-col>
                  <el-col :span="4" style="text-align: center" class="buju">
                    <img src="@/assets/images/home/order/chenggong.png" alt="" style="width: 24px;height: 24px;vertical-align: middle;">
                    <span style="font-size: 16px;font-weight: 400;text-align: right;color: #050505; vertical-align: middle; margin-left: 8px;">及时响应  2分12秒</span>
                  </el-col>
                  <el-col :span="4" style="text-align: center" class="buju">
                    <img src="@/assets/images/home/order/chenggong.png" alt="" style="width: 24px;height: 24px;vertical-align: middle;">
                    <span style="font-size: 16px;font-weight: 400;text-align: right;color: #050505; vertical-align: middle; margin-left: 8px;">及时响应  2分12秒</span>
                  </el-col>
                </el-row>
              </div>
              <div class="ageing_b" style="margin-top: 0;">
                <el-row style="margin: 15px;">
                  <el-col :span="6" style="text-align: center" class="buju">
                    <span class="ageing_b_text_l">师傅操作方式：</span>
                    <span class="ageing_b_text_r">H5、APP、小程序</span>
                  </el-col>
                  <el-col :span="6" style="text-align: center" class="buju">
                    <span class="ageing_b_text_l">接单时间：</span>
                    <span class="ageing_b_text_r">2023-12-14 12:30:54</span>
                  </el-col>
                  <el-col :span="6" style="text-align: center" class="buju">
                    <span class="ageing_b_text_l">到达时间：</span>
                    <span class="ageing_b_text_r">2023-12-14 12:30:54</span>
                  </el-col>
                  <el-col :span="6" style="text-align: center" class="buju">
                    <span class="ageing_b_text_l">完成时间：</span>
                    <span class="ageing_b_text_r">2023-12-14 12:30:54</span>
                  </el-col>
                </el-row>
                <el-row style="margin: 15px;">
                  <el-col :span="6" style="text-align: center" class="buju">
                    <span class="ageing_b_text_l">派单时间：</span>
                    <span class="ageing_b_text_r">2023-12-14 12:30:54</span>
                  </el-col>
                  <el-col :span="6" style="text-align: center" class="buju">
                    <span class="ageing_b_text_l">执行人出发时间：</span>
                    <span class="ageing_b_text_r">2023-12-14 12:30:54</span>
                  </el-col>
                </el-row>
              </div>
            </div>

            <!--公里信息-->
            <div class="ageing" style="margin-top: 20px;" id="gonglixinxi">
              <div class="Datalist">
                <div class="Datalist_l">
                  <div class="Datalist_icon"></div>
                  <div class="Datalist_text">
                    公里信息
                  </div>
                </div>
                <div>
                  <div class="custom" style="margin-left: 20px">
                    <!--<el-button class="custom-button">-->
                    <!--  &lt;!&ndash;el-icon-devops 是自己命名的&ndash;&gt;-->
                    <!--  <i class="el-icon-bianji" />-->
                    <!--  <span style="vertical-align: middle">编辑</span>-->
                    <!--</el-button>-->
                  </div>
                </div>
              </div>
              <div class="ageing_b">
                <el-row style="margin: 15px;">
                  <el-col :span="6" style="text-align: center" class="buju">
                    <span class="ageing_b_text_l" style="font-size: 16px;font-weight: 400;text-align: right;color: #242832;">预计总里程：</span>
                    <span class="ageing_b_text_r" style="font-weight: 700;">{{form.assessmentInformation.expected_total_distance}}</span>
                  </el-col>
                  <el-col :span="6" style="text-align: center" class="buju">
                    <span class="ageing_b_text_l" style="font-size: 16px;font-weight: 400;text-align: right;color: #242832;">实际总里程：</span>
                    <span class="ageing_b_text_r" style="color: #FF9B05; font-weight: 700;">{{form.assessmentInformation.actual_total_distance}}</span>
                  </el-col>
                </el-row>
                <el-row style="margin: 15px;">
                  <el-col :span="6" style="text-align: center" class="buju">
                    <span class="ageing_b_text_l">预计到达里程：</span>
                    <span class="ageing_b_text_r">{{form.assessmentInformation.expected_arrival_course}}</span>
                  </el-col>
                  <el-col :span="6" style="text-align: center" class="buju">
                    <span class="ageing_b_text_l">预计背车里程：</span>
                    <span class="ageing_b_text_r">{{form.assessmentInformation.actual_arrival_course}}</span>
                  </el-col>
                  <el-col :span="6" style="text-align: center" class="buju">
                    <span class="ageing_b_text_l">预计费用：</span>
                    <span class="ageing_b_text_r">￥{{form.assessmentInformation.expected_return_mileage}}</span>
                  </el-col>
                  <el-col :span="6" style="text-align: center" class="buju">
                    <span class="ageing_b_text_l">到达现场效验：</span>
                    <span class="ageing_b_text_r">{{form.assessmentInformation.actual_return_mileage}} </span>
                  </el-col>
                </el-row>
                <el-row style="margin: 15px;">
                  <el-col :span="6" style="text-align: center" class="buju">
                    <span class="ageing_b_text_l">实际到达里程：</span>
                    <span class="ageing_b_text_r">{{form.assessmentInformation.expected_price}}</span>
                  </el-col>
                  <el-col :span="6" style="text-align: center" class="buju">
                    <span class="ageing_b_text_l">实际背车里程：</span>
                    <span class="ageing_b_text_r">{{form.assessmentInformation.actual_price}}</span>
                  </el-col>
                  <el-col :span="6" style="text-align: center" class="buju">
                    <span class="ageing_b_text_l">实际费用：</span>
                    <span class="ageing_b_text_r">￥{{form.assessmentInformation.field_calibration}}</span>
                  </el-col>
                  <el-col :span="6" style="text-align: center" class="buju">
                    <div style="cursor: pointer;">
                      <img style="width: 24px;height: 24px; vertical-align: middle;" src="@/assets/images/home/order/xuanzhong.png" alt="" v-if="form.assessmentInformation.is_plan == ''">
                      <img v-else style="width: 24px;height: 24px; vertical-align: middle; margin-left: 15px;" src="@/assets/images/home/order/weixuanzhong.png" alt="">
                      <span style="font-size: 16px;font-weight: 400;text-align: right;color: #050505; vertical-align: middle; margin-left: 5px;">申请规划</span>
                    </div>
                    <div style="cursor: pointer; margin-left: 10px;">
                      <img style="width: 24px;height: 24px; vertical-align: middle;" src="@/assets/images/home/order/weixuanzhong.png" alt="">
                      <span style="font-size: 16px;font-weight: 400;text-align: right;color: #050505; vertical-align: middle; margin-left: 5px;">实际规划</span>
                    </div>
                  </el-col>
                </el-row>
                <!-- 地图 -->
                <div style="height: 300px;" ref="element">
                  <AMapLoader v-if="lazyLoading === true" :msgData="msgData" v-bind:locationorigin="locationorigin" :locationdestination="locationdestination"></AMapLoader>
                </div>
              </div>
            </div>

            <!--回访信息-->
            <div class="ageing" style="margin-top: 20px;" id="huifangxinxi">
              <div class="Datalist">
                <div class="Datalist_l">
                  <div class="Datalist_icon"></div>
                  <div class="Datalist_text">
                    回访信息
                  </div>
                </div>
              </div>
              <div class="ageing_b" style="padding-left: 20px; padding-right: 20px;">
                <!--<el-row>-->
                <!--  <el-col>-->
                <!--    <span style="font-size: 16px;font-weight: 400;text-align: right;color: #050505;">张世伟</span>-->
                <!--    <span style="font-size: 16px;font-weight: 400;text-align: right;color: #050505;margin-left: 20px;">2023-05-06   19:25:30</span>-->
                <!--  </el-col>-->
                <!--</el-row>-->
                <el-row style="background: #fafafa; padding: 15px; margin-top: 15px;" v-for="(item,index) in form.visit_log" :key="index">
                  <div style="font-size: 16px;font-weight: 400;color: #4b4b4b;">
                    <el-col :span="6">
                      <span>回访满意度：</span>
                      <span>{{item.satisficing}}</span>
                    </el-col>
                    <el-col :span="6">
                      <span>回访情况：</span>
                      <span>
                      <span v-for="(it,idx) in form.visit_log.case" :key="idx" style="margin-right: 10px;">
                        {{it}}
                      </span>
                    </span>
                    </el-col>
                  </div>
                </el-row>
                <div v-if="form.visit_log.length == 0" style="text-align: center;">
                  <el-empty description="暂无数据"></el-empty>
                </div>
              </div>
            </div>

            <!--通话信息-->
            <div class="ageing" style="margin-top: 20px;" id="tonghuaxinxi">
              <div class="Datalist">
                <div class="Datalist_l">
                  <div class="Datalist_icon"></div>
                  <div class="Datalist_text">
                    通话信息
                  </div>
                </div>
              </div>
              <div class="ageing_b" style="padding: 20px;">
                <div v-for="(item,index) in form.call_log" :key="index">
                  <el-row style="display: flex;align-items: center; margin-bottom: 10px;">
                    <div style="font-size: 16px;font-weight: 400;color: #909090;">2023-05-0611:57:54</div>
                    <div style="font-size: 16px;font-weight: 400;color: #050505; margin-left: 20px;">吴帅丽与救援用户通话</div>
                    <audio
                        style="margin-left: 20px;"
                        :src="src"
                        autoplay="autoplay"
                        controls="controls"
                        ref="audio"
                    >Your browser does not support the audio element.</audio>
                  </el-row>
                  <el-row style="display: flex;align-items: center; margin-bottom: 10px;">
                    <div style="font-size: 16px;font-weight: 400;color: #909090;">2023-05-0611:57:54</div>
                    <div style="font-size: 16px;font-weight: 400;color: #050505; margin-left: 20px;">吴帅丽与救援用户通话</div>
                    <audio
                        style="margin-left: 20px;"
                        :src="src"
                        autoplay="autoplay"
                        controls="controls"
                        ref="audio"
                    >Your browser does not support the audio element.</audio>
                  </el-row>
                  <el-row style="display: flex;align-items: center;">
                    <div style="font-size: 16px;font-weight: 400;color: #909090;">2023-05-0611:57:54</div>
                    <div style="font-size: 16px;font-weight: 400;color: #050505; margin-left: 20px;">机器人客服催促服务商派单，已接通18秒</div>
                  </el-row>
                </div>
                <div v-if="form.call_log.length == 0" style="text-align: center;">
                  <el-empty description="暂无数据"></el-empty>
                </div>
              </div>
            </div>

            <!-- 照片审核信息 -->
            <div class="ageing" style="margin-top: 20px;" id="zhaopianshenhexinxi">
              <PhotoReviewInformation v-if="PhotoReviewInformation" :type="form.joinInfo.items" :data="Photoreview"></PhotoReviewInformation>
            </div>

            <!--派单时间轴信息-->
            <div class="ageing" style="margin-top: 20px;" id="paidanshijianzhou">
              <div class="Datalist">
                <div class="Datalist_l">
                  <div class="Datalist_icon"></div>
                  <div class="Datalist_text">
                    派单时间轴信息
                  </div>
                </div>
              </div>
              <div class="ageing_b" style="padding: 20px;">
                <!--时间轴的每一项-->
                <div style="display: flex;align-items: center;margin-bottom: 60px;" v-for="(item,index) in form.timer_shaft" :key="index">
                  <div style="font-size: 16px;font-weight: 500;color: #050505;">案件核销</div>
                  <div style="width: 48px;height: 48px;background: #FFECCD;border-radius: 50%;margin-left: 20px;display: flex;align-items: center;justify-content: center;">
                    <div style="width: 24px;height: 24px;background: #FF9B05;border-radius: 50%;">
                      <div class="xian"></div>
                    </div>
                  </div>
                  <div style="margin-left: 20px;">
                    <div style="font-size: 14px;font-weight: 400;color: #878787;">2022.09.18  14:30</div>
                    <div>
                      <span style="font-size: 16px;font-weight: 400;color: #050505;">【同城救援张三】核销金额：￥360.00，核销方式：线下汇款，汇款方式：微信转账</span>
                      <span style="font-size: 16px;font-weight: 400;color: #FF9B05;margin-left: 20px;">凭证</span>
                    </div>
                  </div>
                </div>
                <div v-if="form.timer_shaft.length == 0" style="text-align: center;">
                  <el-empty description="暂无数据"></el-empty>
                </div>
              </div>
            </div>

            <!--工单照片-->
            <div class="ageing" style="margin-top: 20px;" id="gongdanzhaopian">
              <div class="Datalist">
                <div class="Datalist_l">
                  <div class="Datalist_icon"></div>
                  <div class="Datalist_text">
                    工单照片
                  </div>
                </div>
              </div>
              <div class="ageing_b" style="padding: 20px;">
                <el-row :gutter="15" v-if="form.work_order != null">
                  <el-col :span="6">
                    <div class="gongdanzhaop">
                      <img v-if="form.work_order.car_owner_signature != ''" :src="form.work_order.car_owner_signature" alt="">
                      <el-empty description="" v-else></el-empty>
                      <div>客户签字</div>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div class="gongdanzhaop">
                      <img v-if="form.work_order.contact_signature != ''"  :src="form.work_order.contact_signature" alt="">
                      <el-empty description="" v-else></el-empty>
                      <div>接车人签字</div>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div class="gongdanzhaop">
                      <img v-if="form.work_order.drivers_signature != ''"  :src="form.work_order.drivers_signature" alt="">
                      <el-empty description="" v-else></el-empty>
                      <div>司机签名</div>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div class="gongdanzhaop">
                      <img v-if="form.work_order.electronic_worksheets != ''"  :src="form.work_order.electronic_worksheets" alt="">
                      <el-empty description="" v-else></el-empty>
                      <div>电子工单</div>
                    </div>
                  </el-col>
                </el-row>
                <div v-if="form.work_order == null" style="text-align: center;">
                  <el-empty description="暂无数据"></el-empty>
                </div>
              </div>
            </div>

            <!--订单备注-->
            <!--<div class="ageing" style="margin-top: 20px;" id="dingdanbeizhu">-->
            <!--  <div class="Datalist">-->
            <!--    <div class="Datalist_l">-->
            <!--      <div class="Datalist_icon"></div>-->
            <!--      <div class="Datalist_text">-->
            <!--        订单备注-->
            <!--      </div>-->
            <!--      <div class="Datalist-botton" style="margin-left: 20px">-->
            <!--        <el-button class="custom-button" @click="ReplyWithRemarks()">-->
            <!--          &lt;!&ndash;el-icon-devops 是自己命名的&ndash;&gt;-->
            <!--          <i class="el-icon-tianjia" />-->
            <!--          <span style="vertical-align: middle">添加备注</span>-->
            <!--        </el-button>-->
            <!--      </div>-->
            <!--    </div>-->
            <!--    <div>-->
            <!--      <div class="custom" style="margin-left: 20px">-->
            <!--        <el-button type="primary" plain @click="ReplyWithRemarks()">回复</el-button>-->
            <!--      </div>-->
            <!--    </div>-->
            <!--  </div>-->
            <!--  <div class="ageing_b" style="padding: 20px;">-->
            <!--    <el-row>-->
            <!--      <span style="font-size: 16px;font-weight: 400;color: #2d2f47;">中华保险</span>-->
            <!--      <span style="font-size: 16px;font-weight: 400;color: #2d2f47; margin-left: 20px;">2023-07-21</span>-->
            <!--    </el-row>-->
            <!--    <el-row>-->
            <!--      <span style="font-size: 16px;font-weight: 400;color: #868792;">电瓶标准型号：QAW54a；是否启停：是；初登日期：2021-0509；是否断电拆装:否.</span>-->
            <!--    </el-row>-->
            <!--  </div>-->
            <!--</div>-->

            <!--司机报备-->
            <div class="ageing" style="margin-top: 20px;" id="jishibaobei">
              <div class="Datalist">
                <div class="Datalist_l">
                  <div class="Datalist_icon"></div>
                  <div class="Datalist_text">
                    司机报备
                  </div>
                  <div class="custom" style="margin-left: 20px">
                    <el-button class="custom-button" v-for="(item,index) in form.technician_report" :key="index" @click="Change(item,index)">
                      <span style="vertical-align: middle">{{item.matter}}</span>
                    </el-button>
                  </div>
                </div>
                <div>
                  <!--<div class="custom" style="margin-left: 20px" v-if="isShow1 == 0">-->
                  <!--  <el-button type="primary" plain @click="ReplyWithRemarks()">回复</el-button>-->
                  <!--</div>-->
                  <div class="custom" style="margin-left: 20px" v-if="technician_report.type == 'audit' && technician_report.status == 2">
                    <el-button type="danger" plain @click="bohui()">驳回</el-button>
                    <el-button type="success" plain @click="pass()">通过</el-button>
                  </div>
                </div>
              </div>
              <div class="ageing_b" style="padding: 20px;">
                <div>
                  <el-row>
                    <span style="font-size: 16px;font-weight: 400;color: #2d2f47;">{{technician_report.driver_name}}</span>
                    <span style="font-size: 16px;font-weight: 400;color: #2d2f47; margin-left: 20px;">{{technician_report.create_time}}</span>
                  </el-row>
                  <el-row>
                    <span style="font-size: 16px;font-weight: 400;color: #2d2f47;">{{technician_report.matter}}</span>
                  </el-row>
                  <el-row v-if="technician_report.matter != '订单空驶'">
                    <span style="font-size: 16px;font-weight: 400;color: #868792;">{{technician_report.content}}</span>
                  </el-row>
                  <el-row :gutter="15" v-if="technician_report.matter == '订单空驶'">
                    <el-col :span="8" v-for="(item,index) in technician_report.content.img" :key="index">
                      <img :src="item" alt="">
                    </el-col>
                  </el-row>
                </div>
                <div v-if="form.technician_report.length == 0" style="text-align: center;">
                  <el-empty description="暂无数据"></el-empty>
                </div>
              </div>
            </div>

            <!--关联订单-->
            <div class="ageing" style="margin-top: 20px;" id="guanliandingdan">
              <AssociatedOrder v-if="showAssociatedOrder" @show="QueryshowaddAssociatedOrder" :shuju="data" :data="AssociatedOrder" :children_order="children_order" @huoqu="huoqugetDetails"></AssociatedOrder>
            </div>

            <!--回访信息-->
            <!--<div class="ageing" style="margin-top: 20px;" id="jishibaobei">-->
            <!--  <div class="Datalist">-->
            <!--    <div class="Datalist_l">-->
            <!--      <div class="Datalist_icon"></div>-->
            <!--      <div class="Datalist_text">-->
            <!--        回访信息-->
            <!--      </div>-->
            <!--      <div class="custom" style="margin-left: 20px">-->
            <!--        <el-button class="custom-button" style="background: #FF9B05 !important;border-color: #FF9B05!important;" @click="huifang()">-->
            <!--          &lt;!&ndash;el-icon-devops 是自己命名的&ndash;&gt;-->
            <!--          <i class="el-icon-tianjia" />-->
            <!--          <span style="vertical-align: middle">添加</span>-->
            <!--        </el-button>-->
            <!--      </div>-->
            <!--    </div>-->
            <!--  </div>-->
            <!--  <div class="ageing_b" style="padding: 20px;">-->
            <!--    <el-row style="display: flex;align-items: center; margin-bottom: 10px;">-->
            <!--      <div style="font-size: 16px;font-weight: 400;color: #909090;">2023-05-0611:57:54</div>-->
            <!--      <div style="font-size: 16px;font-weight: 400;color: #050505; margin-left: 20px;">吴帅丽与救援用户通话</div>-->
            <!--      <audio-->
            <!--          style="margin-left: 20px;"-->
            <!--          :src="src"-->
            <!--          autoplay="autoplay"-->
            <!--          controls="controls"-->
            <!--          ref="audio"-->
            <!--      >Your browser does not support the audio element.</audio>-->
            <!--    </el-row>-->
            <!--    <el-row style="display: flex;align-items: center; margin-bottom: 10px;">-->
            <!--      <div style="font-size: 16px;font-weight: 400;color: #909090;">2023-05-0611:57:54</div>-->
            <!--      <div style="font-size: 16px;font-weight: 400;color: #050505; margin-left: 20px;">吴帅丽与救援用户通话</div>-->
            <!--      <audio-->
            <!--          style="margin-left: 20px;"-->
            <!--          :src="src"-->
            <!--          autoplay="autoplay"-->
            <!--          controls="controls"-->
            <!--          ref="audio"-->
            <!--      >Your browser does not support the audio element.</audio>-->
            <!--    </el-row>-->
            <!--    <el-row style="display: flex;align-items: center;">-->
            <!--      <div style="font-size: 16px;font-weight: 400;color: #909090;">2023-05-0611:57:54</div>-->
            <!--      <div style="font-size: 16px;font-weight: 400;color: #050505; margin-left: 20px;">机器人客服催促服务商派单，已接通18秒</div>-->
            <!--    </el-row>-->
            <!--  </div>-->
            <!--</div>-->

            <!--跟进记录-->
            <div class="ageing" style="margin-top: 20px;" id="genjinjilu">
              <div class="Datalist">
                <div class="Datalist_l">
                  <div class="Datalist_icon"></div>
                  <div class="Datalist_text">
                    跟进记录
                  </div>
                  <div class="custom" style="margin-left: 20px">
                    <el-button class="custom-button" style="background: #FF9B05 !important;border-color: #FF9B05!important;" @click="AddFollowUp()">
                      <!--el-icon-devops 是自己命名的-->
                      <i class="el-icon-tianjia" />
                      <span style="vertical-align: middle">添加</span>
                    </el-button>
                  </div>
                </div>
              </div>
              <div class="ageing_b" style="padding: 20px;">
                <div v-for="(item,index) in form.follow_record" :key="index">
                  <el-row>
                    <div style="font-size: 16px;font-weight: 400;color: #050505;">
                      <span>2022.09.18  14:30</span>
                      <span style="margin-left: 20px;">【张三三】</span>
                    </div>
                  </el-row>
                  <el-row style="margin-top: 20px;">
                    <span style="font-size: 16px;font-weight: 400;color: #868792;">跟进记录信息</span>
                  </el-row>
                </div>
                <div v-if="form.follow_record.length == 0" style="text-align: center;">
                  <el-empty description="暂无数据"></el-empty>
                </div>

              </div>
            </div>

            <!--短信电话记录-->
            <div class="ageing" style="margin-top: 20px;" id="duanxindianhuajilu">
              <div class="Datalist">
                <div class="Datalist_l">
                  <div class="Datalist_icon"></div>
                  <div class="Datalist_text">
                    短信电话记录
                  </div>
                </div>
              </div>
              <div class="ageing_b" style="padding: 20px;">
                <!--<div>-->
                <!--  <el-row style="display: flex;align-items: center; margin-bottom: 10px;">-->
                <!--    <div style="font-size: 16px;font-weight: 400;color: #909090;">2023-05-0611:57:54</div>-->
                <!--    <div style="font-size: 16px;font-weight: 400;color: #050505; margin-left: 20px;">吴帅丽与救援用户通话</div>-->
                <!--    <audio-->
                <!--      style="margin-left: 20px;"-->
                <!--      :src="src"-->
                <!--      autoplay="autoplay"-->
                <!--      controls="controls"-->
                <!--      ref="audio"-->
                <!--    >Your browser does not support the audio element.</audio>-->
                <!--  </el-row>-->
                <!--  <el-row style="display: flex;align-items: center; margin-bottom: 10px;">-->
                <!--    <div style="font-size: 16px;font-weight: 400;color: #909090;">2023-05-0611:57:54</div>-->
                <!--    <div style="font-size: 16px;font-weight: 400;color: #050505; margin-left: 20px;">吴帅丽与救援用户通话</div>-->
                <!--    <audio-->
                <!--      style="margin-left: 20px;"-->
                <!--      :src="src"-->
                <!--      autoplay="autoplay"-->
                <!--      controls="controls"-->
                <!--      ref="audio"-->
                <!--    >Your browser does not support the audio element.</audio>-->
                <!--  </el-row>-->
                <!--  <el-row style="display: flex;align-items: center;">-->
                <!--    <div style="font-size: 16px;font-weight: 400;color: #909090;">2023-05-0611:57:54</div>-->
                <!--    <div style="font-size: 16px;font-weight: 400;color: #050505; margin-left: 20px;">机器人客服催促服务商派单，已接通18秒</div>-->
                <!--  </el-row>-->
                <!--</div>-->
                <div style="text-align: center;">
                  <el-empty description="暂无数据"></el-empty>
                </div>
              </div>
            </div>

            <!--客户评价-->
            <div class="ageing" style="margin-top: 20px;" id="chezhupingjia">
              <div class="Datalist">
                <div class="Datalist_l">
                  <div class="Datalist_icon"></div>
                  <div class="Datalist_text">
                    客户评价
                  </div>
                </div>
              </div>
              <div class="ageing_b" style="padding: 20px;">
                <div v-for="(item,index) in form.positive_comment" :key="index">
                  <el-row>
                    <div style="font-size: 16px;font-weight: 400;color: #050505; display: flex;">
                      <span>{{item.create_time}}</span>
                      <span style="margin-left: 20px;">{{item.nickname}}</span>
                      <span style="margin-left: 20px;">
                    <img style="width: 20px;height: 19px; margin-left: 5px;" src="@/assets/images/home/order/pingjia.png" alt="" v-for="(it) in item.star_number">
                  </span>
                    </div>
                  </el-row>
                  <el-row style="margin-top: 20px;">
                    <span style="font-size: 16px;font-weight: 400;color: #868792;">{{item.details}}</span>
                  </el-row>
                </div>
                <div style="text-align: center;" v-if="form.positive_comment.length == 0">
                  <el-empty description="暂无数据"></el-empty>
                </div>
              </div>
            </div>

            <!--客户投诉-->
            <div class="ageing" style="margin-top: 20px;" id="chezhutousu">
              <div class="Datalist">
                <div class="Datalist_l">
                  <div class="Datalist_icon"></div>
                  <div class="Datalist_text">
                    客户投诉
                  </div>
                  <div class="custom" style="margin-left: 20px">
                    <el-button class="custom-button" style="background: #BBB6AC !important;border-color: #BBB6AC!important;" @click="Tousu">
                      <!--el-icon-devops 是自己命名的-->
                      <i class="el-icon-tianjia" />
                      <span style="vertical-align: middle">添加投诉</span>
                    </el-button>
                  </div>
                </div>
              </div>
              <div class="ageing_b" style="padding: 20px;">
                <div v-for="(item,index) in form.complaint" :key="index">
                  <el-row>
                    <div style="font-size: 16px;font-weight: 400;color: #050505; display: flex;">
                      <span>{{item.create_time}}</span>
                      <span style="margin-left: 20px;">【{{item.nickname}}】</span>
                      <span style="margin-left: 20px;">
                      <img style="width: 20px;height: 19px; margin-left: 5px;" src="@/assets/images/home/order/pingjia.png" alt="" v-for="(it) in item.star_number">
                    </span>
                    </div>
                  </el-row>
                  <el-row style="margin-top: 20px;">
                    <span style="font-size: 16px;font-weight: 400;color: #868792;">{{item.details}}</span>
                  </el-row>
                </div>
                <div style="text-align: center;" v-if="form.complaint.length == 0">
                  <el-empty description="暂无数据"></el-empty>
                </div>

              </div>
            </div>

            <!--催单-->
            <div class="ageing" style="margin-top: 20px;" id="cuidan">
              <div class="Datalist">
                <div class="Datalist_l">
                  <div class="Datalist_icon"></div>
                  <div class="Datalist_text">
                    催单
                  </div>
                  <div class="custom" style="margin-left: 20px">
                    <el-button class="custom-button" style="background: #F3505B !important;border-color: #F3505B!important;" @click="cuidan">
                      <!--el-icon-devops 是自己命名的-->
                      <i class="el-icon-cuidan" />
                      <span style="vertical-align: middle">催单</span>
                    </el-button>
                  </div>
                </div>
              </div>
              <div class="ageing_b" style="padding: 20px;">
                <div v-for="(item,index) in form.reminder_log" :key="index">
                  <el-row>
                    <div style="font-size: 16px;font-weight: 400;color: #050505; display: flex;">
                      <span>{{item.time}}</span>
                      <span style="margin-left: 20px;">【{{item.nickname}}】</span>
                      <span style="margin-left: 20px;">
                      <!--<img style="width: 20px;height: 19px; margin-left: 5px;" src="@/assets/images/home/order/pingjia.png" alt="">-->
                        <!--<img style="width: 20px;height: 19px; margin-left: 5px;" src="@/assets/images/home/order/pingjia.png" alt="">-->
                        <!--<img style="width: 20px;height: 19px; margin-left: 5px;" src="@/assets/images/home/order/pingjia.png" alt="">-->
                        <!--<img style="width: 20px;height: 19px; margin-left: 5px;" src="@/assets/images/home/order/pingjia.png" alt="">-->
                        <!--<img style="width: 20px;height: 19px; margin-left: 5px;" src="@/assets/images/home/order/pingjia.png" alt="">-->
                    </span>
                    </div>
                  </el-row>
                  <el-row style="margin-top: 20px;">
                    <span style="font-size: 16px;font-weight: 400;color: #868792;">{{item.details}}</span>
                    <el-button type="primary" style="margin-left: 10px;" plain @click="ReplyWithRemarks()">回复</el-button>
                  </el-row>
                </div>
                <div style="text-align: center;" v-if="form.reminder_log.length == 0">
                  <el-empty description="暂无数据"></el-empty>
                </div>
              </div>
            </div>

            <!--修改记录-->
            <div class="ageing" style="margin-top: 20px;" id="xiugaijilu">
              <div class="Datalist">
                <div class="Datalist_l">
                  <div class="Datalist_icon"></div>
                  <div class="Datalist_text">
                    修改记录
                  </div>
                </div>
              </div>
              <div class="ageing_b" style="padding: 20px;">
                <div v-for="(item,index) in form.update_log" :key="index">
                  <el-row :gutter="15">
                    <el-col :span="4">
                      <div style="font-size: 16px;font-weight: 400;color: #868792;">{{item.time}} </div>
                    </el-col>
                    <el-col :span="20">
                      <div style="font-size: 16px;font-weight: 400;color: #050505;">
                        <span>{{item.operator_name}}</span>
                        <span style="color: rgb(161 163 167); margin-left: 10px;margin-right: 10px;">修改了</span>
                        <!--<span>申请救援地</span>-->
                        <span>由</span>
                        <span>【{{item.source_value}}】</span>
                        <span style="color: rgb(161 163 167); margin-left: 10px;margin-right: 10px;">修改为</span>
                        <span>【{{item.amend_value}}】</span>
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <div style="text-align: center;" v-if="form.update_log.length == 0">
                  <el-empty description="暂无数据"></el-empty>
                </div>
              </div>
            </div>
          </el-card>
        </el-form>

        <!--按钮-->
        <!--<div class="card_bottom">-->
        <!--  <el-button style="width: 200px !important;">取消</el-button>-->
        <!--  <el-button type="warning" style="width: 200px !important; margin-left: 20px;">提交</el-button>-->
        <!--</div>-->
      </div>

      <!--对账信息 模块-->
      <div  v-if='isShow == 1'>
        <div>
          <ReconciliationInformation></ReconciliationInformation>
        </div>
      </div>

      <!--订单消息 模块-->
      <div  v-if='isShow == 2'>
        <div>
          <OrderMessage></OrderMessage>
        </div>
      </div>


      <!-- 报备弹窗 -->
      <reporting-edit v-if="showreporting === true" :data="reportingcurrent" :visible.sync="showreporting" @done="getDetails"/>

      <!-- 订单审核弹窗 -->
      <orderReview-edit v-if="showorderReview === true" :data="orderReviewcurrent" :visible.sync="showorderReview" />

      <!-- 订单复审弹窗 -->
      <toReviewACase-edit v-else-if="showtoReviewACase === true" :data="toReviewACasecurrent" :visible.sync="showtoReviewACase" />

      <!--改派弹窗-->
      <reassignment-edit v-if="showreassignment === true" :dict="dict" :data="reassignmentcurrent" :visible.sync="showreassignment" @freshenMap="freshenatlas"/>

      <!-- 添加跟进弹窗 -->
      <addFollowUp-edit :data="addFollowUpcurrent" :visible.sync="showaddFollowUp"/>

      <!--关联订单弹窗-->
      <AssociatedOrderEdit :data="addAssociatedOrdercurrent" :visible.sync="showaddAssociatedOrder" @showAdd="showadd" @showName="showname" @shuaxin="renovation"/>

      <!--订单收回弹窗-->
      <orderRecovery-edit :data="orderRecoverycurrent" :visible.sync="showorderRecovery"/>

      <!--添加回访弹窗-->
      <addReturnVisit-edit v-if="showaddReturnVisit === true" :dict="dict" :data="addReturnVisitcurrent" :visible.sync="showaddReturnVisit"/>

      <!--客户话术弹窗-->
      <customerScript-edit :data="customerScriptEditcurrent" :visible.sync="showcustomerScriptEdit"/>

      <!--备注回复-->
      <replyWithRemarks-edit :data="replyWithRemarksEditcurrent" :visible.sync="showreplyWithRemarksEdit"/>

      <!--报备驳回-->
      <reasonForRejectionOfReport-edit v-if="showreasonForRejectionOfReport === true" :id="id" :data="reasonForRejectionOfReportcurrent" :visible.sync="showreasonForRejectionOfReport"/>

      <!--添加回访-->
      <returnVisit-edit v-if="showreturnVisit === true" :data="currentreturnVisit" :dict="dict" :visible.sync="showreturnVisit"/>

      <!--催单-->
      <reminder-edit :data="currentreminder" :visible.sync="showreminder"/>

      <!--投诉-->
      <complaint-edit :data="currentcomplaint" :visible.sync="showcomplaint"/>

      <!--外协-->
      <coordination-edit :data="currentcoordination" :visible.sync="showcoordination"/>

      <!--标记-->
      <el-dialog
          center
          title="标记"
          :visible.sync="dialogVisible"
          width="30%"
          :before-close="handleClose">
      <span>
        <el-button type="primary" plain @click="Tousu">投诉订单</el-button>
        <el-button type="primary" plain @click="waixie">外协订单</el-button>
      </span>
        <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
      </el-dialog>

      <el-dialog
          center
          title="选择地址"
          :visible.sync="selectaddress"
          width="30%"
          :before-close="handleClose">
        <div>
          <!--<div style="display: flex;">-->
          <!--  <el-input clearable v-model="keywords" placeholder="请输入位置"/>-->
          <!--  <el-button style="margin-left: 10px;" @click="onSearch">搜索</el-button>-->
          <!--</div>-->
          <iframe id="mapPage" width="100%" height="700px;" frameborder="0" v-bind:src="keyUrl"></iframe>
        </div>
        <div slot="footer">
          <!--<el-button-->
          <!--  @click="dialogVisible = false">取消-->
          <!--</el-button>-->
          <el-button
              style="margin-left: 20px;"
              type="primary"
              @click="selectaddress = false">确定
          </el-button>
        </div>
      </el-dialog>
    </div>

    <!--新建订单-->
    <order-edit v-if="showEdit == true" @show="ShowOrder" :name="orderName" :data="content" :dict="dict"></order-edit>

  </div>
</template>

<script>
//获取高德地图的key
import setting from '@/config/config'

//引入照片审核信息模块
import PhotoReviewInformation from './PhotoReviewInformation.vue'
//引入关联订单模块
import AssociatedOrder from './AssociatedOrder.vue'

//引入对账信息模块
import ReconciliationInformation from './ReconciliationInformation.vue'
//引入订单消息模块
import OrderMessage from './OrderMessage.vue'

// 引入报备弹窗
import ReportingEdit from './components/reporting-edit.vue';
//引入订单审核弹窗
import OrderReviewEdit from './components/orderReview-edit.vue'
// 引入订单复审弹窗
import ToReviewACaseEdit from './components/toReviewACase-edit.vue'
// 引入改派弹窗
import ReassignmentEdit from './components/reassignment-edit.vue'
// 引入添加跟进弹窗
import AddFollowUpEdit from './components/addFollowUp-edit.vue'
//引入关联订单弹窗
import AssociatedOrderEdit from './components/addAssociatedOrder-edit.vue'
//引入订单收回弹窗
import OrderRecoveryEdit from './components/orderRecovery-edit.vue'
//引入添加回访弹窗
import AddReturnVisitEdit from './components/addReturnVisit-edit.vue'
//引入客户话术弹窗
import CustomerScriptEdit from './components/customerScript-edit.vue'
//引入备注回复弹窗
import ReplyWithRemarksEdit from './components/replyWithRemarks-edit.vue'
//引入报备驳回原因
import ReasonForRejectionOfReportEdit from './components/reasonForRejectionOfReport-edit.vue'
//引入添加回访
import ReturnVisitEdit from './components/returnVisit-edit.vue'
//引入催单
import ReminderEdit from './components/reminder-edit.vue'
//引入投诉
import ComplaintEdit from './components/complaint-edit.vue'
//引入外协
import CoordinationEdit from './components/coordination-edit.vue'

//引入创建订单
import OrderEdit from './add.vue'

// 引入的接口
import {orderDetails, Editupdate_basics, Editupdate_join_info, Savereport_audit} from "@/api/orderModule";
//服务项目接口
import {getget_services_available} from '@/api/yunli'

export default {
  name: 'index',
  // 支柱道具属性
  props: {
    //数据
    data: Object,
    //字典
    dict: Object
  },
  // 组件生效
  components: {
    PhotoReviewInformation,
    AssociatedOrder,
    ReconciliationInformation,
    OrderMessage,
    ReportingEdit,
    OrderReviewEdit,
    ToReviewACaseEdit,
    ReassignmentEdit,
    AddFollowUpEdit,
    AssociatedOrderEdit,
    OrderRecoveryEdit,
    AddReturnVisitEdit,
    CustomerScriptEdit,
    ReplyWithRemarksEdit,
    ReasonForRejectionOfReportEdit,
    ReturnVisitEdit,
    ReminderEdit,
    ComplaintEdit,
    CoordinationEdit,
    OrderEdit
  },
  // Data数据
  data() {
    return {
      form:{
        basics:{},
        joinInfo:{},
        settlementInformation:{},
        facilitatorSettlementInformation:{},
      },

      isShow:0,
      activeList:[
        {
          img: require('../../../assets/images/home/custom/icon1active.png'),
          name:'基本信息',
        },
        {
          img: require('../../../assets/images/home/custom/icon2.png'),
          name:'对账信息',
        },
        {
          img: require('../../../assets/images/home/custom/icon3.png'),
          name:'订单消息',
        },
      ],

      // 注意事项字段
      textarea:'',

      src: "",

      //报备数据
      reportingcurrent:null,
      //报备弹窗
      showreporting:false,

      //订单审核
      orderReviewcurrent:null,
      //审核弹窗
      showorderReview:false,

      //订单复审
      toReviewACasecurrent:null,
      showtoReviewACase:false,

      //改派
      reassignmentcurrent:null,
      showreassignment:false,

      //添加跟进
      addFollowUpcurrent:null,
      showaddFollowUp:false,

      //关联订单
      showAssociatedOrder:true,
      addAssociatedOrdercurrent:null,
      showaddAssociatedOrder:false,

      //订单收回
      orderRecoverycurrent:null,
      showorderRecovery:false,

      //添加回访
      addReturnVisitcurrent:null,
      showaddReturnVisit:false,

      //客户话术
      customerScriptEditcurrent:null,
      showcustomerScriptEdit:false,

      //备注回复
      replyWithRemarksEditcurrent:null,
      showreplyWithRemarksEdit:false,

      //报备驳回原因
      reasonForRejectionOfReportcurrent:null,
      showreasonForRejectionOfReport:false,

      //添加回访
      currentreturnVisit:null,
      showreturnVisit:false,

      //催单
      currentreminder:null,
      showreminder:false,

      //投诉
      currentcomplaint:null,
      showcomplaint:false,

      //外协
      currentcoordination:null,
      showcoordination:false,

      //基本信息编辑状态
      isEdit:false,

      //接单信息编辑状态
      orderReceivingisEdit:false,

      //结算信息编辑状态
      settlementEdit:false,

      //地图组件延时加载
      lazyLoading:false,
      //高度
      msgData:0,

      reportingList:[
        {
          label:'报备1',
          id:1,
        },
        {
          label:'报备2',
          id:2,
        },
        {
          label:'报备3',
          id:3,
        }
      ],
      isShow1:0,

      //标记
      dialogVisible: false,

      //用户传递的经度纬度
      locationorigin:[],
      locationdestination:[],

      //选择地址
      selectaddress:false,
      //iframe
      keyUrl:'',

      //服务项目
      ServicesAvailable:[],

      //照片审核信息
      Photoreview:[],
      //照片审核信息
      PhotoReviewInformation:false,

      //关联订单
      AssociatedOrder:[],
      //子订单
      children_order: [],

      //报备
      technician_report:{},
      id:0,

      //创建订单
      showEdit:false,
      orderName:'',
      content:null,

    }
  },
  // 计算属性 类似于 data 概念
  computed: {},
  // 监控 data 中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created() {
  },
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    // 调用获取详情接口
    this.getDetails();

    //获取地图左侧内容的高度 传值给地图子组件
    // this.msgData = this.$refs.element.offsetHeight - 20;  //减去的20是内边距距离
    this.msgData = 300 - 20;  //减去的20是内边距距离

    //加载照片审核信息组件
    this.PhotoReviewInformation = true;

    // 高德地图
    window._AMapSecurityConfig = {
      securityJsCode: setting.secretkey,
    }

    //加载地图组件
    this.lazyLoading = true;


    // 腾讯地图组件监听
    window.addEventListener(
        'message',
        function (event) {
          // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
          var loc = event.data;
          if (loc && loc.module === 'locationPicker') {
            // 防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
            window.parent.selectAdderss(loc);
          }
        },
        false,
    );
    window.selectAdderss = this.selectAdderss;

    // 获取服务项目
    this.getFuwuxiangmu();
  },
  // 方法集合
  methods: {
    //获取详情
    getDetails(){
      let where = {
        odd: this.data.order_id
      }
      orderDetails(this.data.id,where).then(res => {
        console.log(res);
        this.form = res.data;
        this.form.basics = res.data.basics;
        this.form.joinInfo = res.data.joinInfo;
        this.form.settlementInformation = res.data.settlementInformation;
        this.form.facilitatorSettlementInformation = res.data.facilitatorSettlementInformation;
        // 照片审核
        this.Photoreview = res.data.photo_review_information;
        // 关联订单
        this.AssociatedOrder = res.data.correlation_order;
        // 子订单
        this.children_order = res.data.children_order;

        if(res.data.technician_report.length != 0){
          //报备默认显示
          this.technician_report = res.data.technician_report[0];
        }

        //赋值当前订单救援地路径
        this.locationorigin[0] = this.form.assessmentInformation.starting_point_coord[0];
        this.locationorigin[1] = this.form.assessmentInformation.starting_point_coord[1];
        this.locationdestination[0] = this.form.assessmentInformation.destination_coord[0];
        this.locationdestination[1] = this.form.assessmentInformation.destination_coord[1];

        this.showAssociatedOrder = false;
        this.$nextTick(() => {
          this.showAssociatedOrder = true;
        })

        this.$forceUpdate();
      })
    },

    //获取服务项目
    getFuwuxiangmu(){
      getget_services_available().then(res => {
        this.ServicesAvailable = res.data;
      })
    },

    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    // 选项卡点击事件
    xuanxiangclick(item,index){
      console.log(item);
      console.log(index);
      this.isShow = index;
      if(item.name == '基本信息'){
        this.activeList[0].img = require('../../../assets/images/home/custom/icon1active.png');
        this.activeList[1].img = require('../../../assets/images/home/custom/icon2.png');
        this.activeList[2].img = require('../../../assets/images/home/custom/icon3.png');
      }else if(item.name == '对账信息'){
        this.activeList[0].img = require('../../../assets/images/home/custom/icon1.png');
        this.activeList[1].img = require('../../../assets/images/home/custom/icon2active.png');
        this.activeList[2].img = require('../../../assets/images/home/custom/icon3.png');
      }else if(item.name == '订单消息'){
        this.activeList[0].img = require('../../../assets/images/home/custom/icon1.png');
        this.activeList[1].img = require('../../../assets/images/home/custom/icon2.png');
        this.activeList[2].img = require('../../../assets/images/home/custom/icon3active.png');
      }
    },

    // 返回按钮
    returnPage(){
      this.$emit('showDetalis',false) ;
    },

    // 播放组件
    handlePlay(row){
      this.src = row.filePath;
      thia.play();
    },
    // 播放
    play(){
      this.$refs.audio.play();
    },
    // 音频暂停
    stop(){
      this.$refs.audio.pause();
      this.$refs.audio.currentTime = 0;
    },

    // 锚点跳转连接 平滑过度效果
    goAnchor(selector){
      this.$el.querySelector(selector).scrollIntoView({
        behavior: "smooth",  // 平滑过渡
        block:    "start"  // 上边框与视窗顶部平齐。默认值
      });
    },

    //点击报备按钮
    Reporting(){
      this.reportingcurrent = this.data;
      this.showreporting = true;
    },

    //点击审核按钮
    ToExamine(){
      this.orderReviewcurrent = this.data;
      this.showorderReview = true;
    },

    //点击复审按钮
    ToReviewACase(){
      this.toReviewACasecurrent = this.data;
      this.showtoReviewACase = true;
    },

    //点击改派按钮
    Reassignment(){
      this.reassignmentcurrent = this.data;
      this.showreassignment = true;
    },
    //接收改派组件传递的数据
    freshenatlas(){
      // 刷新下地图组件
      this.lazyLoading = false;
      this.$nextTick(() => {
        this.lazyLoading = true;
      })
    },

    //添加跟进按钮
    AddFollowUp(){
      this.showaddFollowUp = true;
    },

    //关联订单子组件传递的值
    QueryshowaddAssociatedOrder(val){
      console.log(val)
      this.showaddAssociatedOrder = true;
      this.addAssociatedOrdercurrent = this.data;
      this.getDetails();
      this.$forceUpdate();
    },

    //订单收回
    TakeBack(){
      this.showorderRecovery = true;
    },

    //回访按钮
    ReturnVisit(){
      console.log(this.data);
      console.log(this.dict);
      let that = this;
      that.addReturnVisitcurrent = this.data;
      console.log(that.addReturnVisitcurrent);
      this.showaddReturnVisit = true;
    },

    //客户话术弹窗
    CustomerScript(){
      this.showcustomerScriptEdit = true;
    },

    //备注回复
    ReplyWithRemarks(){
      this.showreplyWithRemarksEdit = true;
    },


    //点击基本信息编辑按钮
    Edit(){
      this.isEdit = true;
    },
    //基本信息确认按钮
    infoConfirm(){
      this.isEdit = false;
      Editupdate_basics(this.data.id,this.form.basics).then(res => {
        console.log(res);
        if(res.code == 200){
          this.$message.success(res.msg);
          this.isEdit = false;
          this.getDetails();
        }else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.msg)
      })
    },
    //基本信息取消按钮
    infoCancel(){
      this.isEdit = false;
    },

    //点解接单信息的编辑
    orderReceiving(){
      this.orderReceivingisEdit = true;
    },
    //确认
    orderReceivingConfirm(){
      this.orderReceivingisEdit = false;
      Editupdate_join_info(this.data.id,this.form.joinInfo).then(res => {
        console.log(res);
        if(res.code == 200){
          this.$message.success(res.msg);
          this.orderReceivingisEdit = false;
          this.getDetails();
        }else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.msg)
      })
    },
    //取消
    orderReceivingCancel(){
      this.orderReceivingisEdit = false;
    },

    //结算信息的编辑
    settlement(){
      this.settlementEdit = true;
    },
    //确认
    settlementConfirm(){
      this.settlementEdit = false;
    },
    //取消
    settlementCancel(){
      this.settlementEdit = false;
    },

    //报备
    Change(item,index){
      this.isShow1 = index;
      this.technician_report = item;
    },
    //驳回
    bohui(){
      this.showreasonForRejectionOfReport = true;
      this.reasonForRejectionOfReportcurrent = this.technician_report;
      this.id = this.data.id
    },
    //通过
    pass(){
      let data = {
        indexes: this.technician_report.indexes,
        status: '2'
      }
      Savereport_audit(this.data.id,data).then(res => {
        if(res.code == 200){
          this.$message.success(res.msg);
          this.getDetails();
        }else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.msg)
      })
    },

    //添加回访
    huifang(){
      this.showreturnVisit = true;
    },

    //催单
    cuidan(){
      this.showreminder = true;
    },

    //投诉
    Tousu(){
      this.showcomplaint = true;
    },

    //标记
    biaoji(){
      this.dialogVisible = true;
    },
    handleClose(done) {
      this.dialogVisible = false;
      this.selectaddress = false;
    },
    //外协
    waixie(){
      this.showcoordination = true;
    },


    // 腾讯地图选址组件
    //点击选择地址
    opensiteselection(number){
      this.selectaddress = true;
      // 用于判断是起点还是终点
      this.origin = number;
      //打开腾讯地图选址组件
      let keys = setting.Tencentkey;
      this.keyUrl = `https://apis.map.qq.com/tools/locpicker?type=1&key=${keys}&referer=myapp&coordtype=1&sig=ytLgIfdiuGLdxRAl1MZ7qwxPOI3W5K9k`;
    },
    // 选择经纬度
    selectAdderss(data) {
      console.log(data)
      // 救援起点
      if(this.origin == 0){
        // 救援起点的位置
        this.form.basics.starting_point = data.poiaddress + data.poiname;
        // 隐藏选址弹窗组件
        this.selectaddress = false;
        this.lazyLoading = !this.lazyLoading;
      }else {
        //救援终点位置
        this.form.basics.destination = data.poiaddress + data.poiname;
        // 隐藏选址弹窗组件
        this.selectaddress = false;
        this.lazyLoading = !this.lazyLoading;
      }
    },

    // 接受创建订单子组件传递的值
    ShowOrder(value){
      console.log(value);
      // 隐藏子组件
      this.showEdit = value;
      this.getDetails()
    },

    //接收关联订单传递的值
    showadd(value){
      console.log(value)
      this.showEdit = value;
      this.content = this.data;
    },
    showname(value){
      this.orderName = value;
      this.content = this.data;
    },
    renovation(value){
      this.getDetails();
    },
    huoqugetDetails(){
      console.log('刷新')
      this.getDetails();
    },

  },
  // 生命周期 - 更新之前
  beforeUpdate() {
  },
  // 生命周期 - 更新之后
  updated() {
  },
  // 过滤器
  filters: {},
  // 生命周期 - 创建之前
  beforeCreate() {
  },
  // 生命周期 - 挂载之前
  beforeMount() {
  },
  // 生命周期 - 销毁之前
  beforeDestroy() {
  },
  // 生命周期 - 销毁完成
  destroyed() {
  },
  // 如果页面有 keep-alive 缓存功能,这个函数会触发
  // 进入的时候触发
  activated() {
  },
  // 离开的时候触发
  deactivated() {
  }
}
</script>

<style lang="scss">
// 引入css
@import './style.scss';
.xian{
  width: 1px;
  height: 100px;
  border: 3px dashed #ff9b05;
  margin: auto;
}

.gongdanzhaop{
  width: 100%;
  text-align: center;
  img{
    width: 100%;
    height: 240px;
  }
  div{
    font-size: 16px;
    font-weight: 400;
    color: #050505;
    margin-top: 15px;
  }
}
</style>
