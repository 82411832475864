<template>
  <!--订单 待接单 创建订单-->
  <div class="xiaadan">
    <div class="title" style="margin-bottom: 0 !important;">
      <div class="title_t" v-if="name == 'addpunish'">
        <span>创建处罚订单</span>
      </div>
      <div class="title_t" v-else>
        <span>创建订单</span>
      </div>
      <div class="title_r" @click="returnPage">
        <span>返回</span>
      </div>
    </div>

    <el-form ref="form" :model="form" label-width="80px">
      <el-row :gutter="15">
        <!-- 左侧部分 -->
        <el-col :span="18">
          <div ref="element">
            <!--客户信息-->
            <el-card shadow="never">
              <div class="Datalist">
                <div class="Datalist_l">
                  <div class="Datalist_icon"></div>
                  <div class="Datalist_text">
                    客户信息
                  </div>
                </div>
              </div>
              <el-col :span="12" style="margin-top: 15px;">
                <el-form-item label="客户名称:">
                  <el-select
                      clearable
                      v-model="form.channel_id"
                      placeholder="请选择"
                      class="ele-fluid">
                    <el-option v-for="(item) in client" :label="item.channel_name" :value="parseInt(item.id)"/>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-card>

            <!--订单信息-->
            <el-card shadow="never">
              <div class="Datalist">
                <div class="Datalist_l">
                  <div class="Datalist_icon"></div>
                  <div class="Datalist_text">
                    订单信息
                  </div>
                </div>
              </div>
              <el-row style="margin-top: 15px;">
                <el-col :span="8">
                  <el-form-item label="订单类型:">
                    <el-select
                        :disabled="disabled"
                        clearable
                        v-model="form.rescue_type"
                        placeholder="请选择"
                        class="ele-fluid">
                      <el-option v-for="(item) in ServicesAvailable" :label="item.label" :value="item.serial_number"/>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="报案号:">
                    <el-input
                        clearable
                        v-model="form.case_number"
                        placeholder="请输入报案号"/>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="保单号:">
                    <el-input
                        clearable
                        v-model="form.policy_number"
                        placeholder="请输入保单号"/>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="8">
                  <el-form-item label="汽车类型:">
                    <el-select
                        clearable
                        v-model="form.car_type"
                        placeholder="请选择"
                        class="ele-fluid">
                      <el-option
                          v-for="(item) in dict.type.automobile_type"
                          :label="item.label"
                          :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="品牌型号:">
                    <el-input
                        clearable
                        v-model="form.car_brand"
                        placeholder="请输入报案号"/>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="救援环境:">
                    <el-select
                        clearable
                        v-model="form.rescue_environment"
                        placeholder="请选择"
                        class="ele-fluid">
                      <el-option
                          v-for="(item) in dict.type.environment"
                          :label="item.label"
                          :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="8">
                  <el-form-item label="救援起点:">
                    <el-button @click="opensiteselection(0)">点击选择</el-button>
                    <!--<el-input-->

                    <!--    :disabled="true"-->
                    <!--    clearable-->
                    <!--    v-model="form.name"-->
                    <!--    placeholder="请输入救援起点"/>-->
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="救援终点:">
                    <!--<el-input-->
                    <!--    :disabled="true"-->
                    <!--    clearable-->
                    <!--    v-model="form.name"-->
                    <!--    placeholder="请输入救援终点"/>-->
                    <el-button @click="opensiteselection(1)">点击选择</el-button>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="辅轮:">
                    <el-select
                        clearable
                        v-model="form.is_pulley"
                        placeholder="请选择"
                        class="ele-fluid">
                      <el-option
                          v-for="(item) in dict.type.jockeypulley"
                          :label="item.label"
                          :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="8">
                  <el-form-item label="客户:">
                    <el-input
                        clearable
                        v-model="form.owners"
                        placeholder="请输入客户姓名"/>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="客户电话:">
                    <el-input
                        clearable
                        v-model="form.owners_phone"
                        placeholder="请输入客户电话"/>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="车牌:">
                    <el-input
                        clearable
                        v-model="form.license_plate"
                        placeholder="请输入客户电话"/>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="8">
                  <el-form-item label="车架号:">
                    <el-input
                        clearable
                        v-model="form.vin"
                        placeholder="请输入车架号"/>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="免拖里程:">
                    <el-input
                        clearable
                        v-model="form.free_trailer"
                        placeholder="请输入免拖里程"/>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="">
                    <el-checkbox v-model="form.is_accident" :true-label="1" :false-label="0">事故</el-checkbox>
                    <el-checkbox v-model="form.is_appointment" :true-label="1" :false-label="0">预约</el-checkbox>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row v-if="form.is_appointment == 1">
                <el-col :span="24">
                  <el-form-item label="预约时间:">
                    <el-time-picker
                        value-format="HH:mm:ss"
                        is-range
                        v-model="form.appointment_time"
                        range-separator="至"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        placeholder="选择时间范围">
                    </el-time-picker>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="24">
                  <el-form-item label="备注:">
                    <el-input
                        clearable
                        type="textarea"
                        v-model="form.remark"
                        placeholder="请输入备注信息"/>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-card>

            <!--创建订单的  服务信息-->
            <el-card shadow="never">
              <div class="Datalist">
                <div class="Datalist_l">
                  <div class="Datalist_icon"></div>
                  <div class="Datalist_text">
                    服务信息
                  </div>
                </div>
              </div>
              <el-col :span="6" style="margin-top: 15px;">
                <el-form-item label="服务商:">
                  <el-select
                      clearable
                      :disabled="true"
                      v-model="form.facilitator_id"
                      placeholder="请选择"
                      class="ele-fluid">
                    <el-option label="服务商1" value="1"/>
                    <el-option label="服务商2" value="2"/>
                    <el-option label="服务商3" value="3"/>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6" style="margin-top: 15px;">
                <el-form-item label="司机:">
                  <el-select
                    clearable
                    v-model="form.driver_id"
                    placeholder="请选择"
                    class="ele-fluid">
                    <el-option v-for="(item) in driver" :label="item.realname" :value="item.id"/>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-card>
          </div>
        </el-col>

        <!-- 右侧部分 -->
        <el-col :span="6">
          <el-card shadow="never">
            <AMapLoader v-if="lazyLoading === true" :msgData="msgData" v-bind:locationorigin="locationorigin" :locationdestination="locationdestination"></AMapLoader>
          </el-card>
        </el-col>

      </el-row>

    </el-form>

    <!--按钮-->
    <div class="card_bottom">
      <el-button style="width: 200px !important;" @click="cancel">取消</el-button>
      <el-button type="warning" style="width: 200px !important; margin-left: 20px;" @click="save()">生成订单</el-button>
    </div>

    <el-dialog
        center
        title="选择地址"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
      <div>
        <!--<div style="display: flex;">-->
        <!--  <el-input clearable v-model="keywords" placeholder="请输入位置"/>-->
        <!--  <el-button style="margin-left: 10px;" @click="onSearch">搜索</el-button>-->
        <!--</div>-->
        <iframe id="mapPage" width="100%" height="700px;" frameborder="0" v-bind:src="keyUrl"></iframe>
      </div>
      <div slot="footer">
        <!--<el-button-->
        <!--  @click="dialogVisible = false">取消-->
        <!--</el-button>-->
        <el-button
            style="margin-left: 20px;"
            type="primary"
            @click="dialogVisible = false">确定
        </el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
//服务项目接口
import {getget_services_available, getself_driver} from '@/api/yunli'
import {Adddelay_join} from "@/api/orderModule";
import {get_select} from "@/api/custom";
// 引入配置
import setting from "@/config/config";

export default {
  // 支柱道具属性
  props: {
    name: String,
    // required: true
    // 字典数据
    dict: Object,
    data: Object,
  },
  // 组件生效
  components: {},
  // Data数据
  data() {
    return {
      form:{
        starting_point_coord:[],
        destination_coord:[],
      },

      //地图组件延时加载
      lazyLoading:false,
      //高度
      msgData:0,

      //服务项目
      ServicesAvailable:[],

      //选择地址
      dialogVisible:false,
      //iframe
      keyUrl:'',

      //用户传递的经度纬度
      locationorigin:[],
      locationdestination:[],

      //起点或者是终点
      origin:0,

      //客户列表
      client:[],
      //司机列表
      driver:[],

      disabled:true,

    }
  },

  // 计算属性 类似于 data 概念
  computed: {},

  // 监控 data 中的数据变化
  watch: {},

  created() {

  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    //获取地图左侧内容的高度 传值给地图子组件
    this.msgData = this.$refs.element.offsetHeight - 20;  //减去的20是内边距距离
    //加载地图组件
    this.lazyLoading = true;

    //获取服务项目
    this.getFuwuxiangmu();

    // 腾讯地图组件监听
    window.addEventListener(
        'message',
        function (event) {
          // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
          var loc = event.data;
          if (loc && loc.module === 'locationPicker') {
            // 防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
            window.parent.selectAdderss(loc);
          }
        },
        false,
    );
    window.selectAdderss = this.selectAdderss;

    // 调用获取客户列表
    this.getKehu();
    // 获取司机列表
    this.getdriver();

    console.log(this.name)
    if(this.name == 'addpunish'){
      this.disabled = true;
    }else {
      this.disabled = false;
    }

  },
  // 方法集合
  methods: {
    //获取客户名称列表
    getKehu(){
      get_select().then(res => {
        this.client = res.data;
      })
    },

    // 获取列表
    getdriver(){
      let where = {
        page:1,
        limit: 50
      }
      getself_driver(where).then(async (res) => {
        console.log(res)
        this.driver = res.data.list;
      }).catch((res) => {
        this.$Message.error(res.msg);
      })
    },

    //获取服务项目
    getFuwuxiangmu(){
      getget_services_available().then(res => {
        this.ServicesAvailable = res.data;
      })
    },

    //点击救援起点
    opensiteselection(number){
      this.dialogVisible = true;
      // 用于判断是起点还是终点
      this.origin = number;
      //打开腾讯地图选址组件
      let keys = setting.Tencentkey;
      this.keyUrl = `https://apis.map.qq.com/tools/locpicker?type=1&key=${keys}&referer=myapp&coordtype=1&sig=ytLgIfdiuGLdxRAl1MZ7qwxPOI3W5K9k`;
    },

    // 选择经纬度
    selectAdderss(data) {
      console.log(data)
      // 救援起点
      if(this.origin == 0){
        // 救援起点的位置
        this.form.starting_point = data.poiaddress + data.poiname;
        // 救援起点的经度纬度
        this.form.starting_point_coord[0] = data.latlng.lat;
        this.form.starting_point_coord[1] = data.latlng.lng;
        // 隐藏选址弹窗组件
        this.dialogVisible = false;
        // 赋值给传递的起点经度纬度
        this.locationorigin[0] = data.latlng.lat;
        this.locationorigin[1] = data.latlng.lng;
        this.lazyLoading = !this.lazyLoading;
      }else {
        //救援终点位置
        this.form.destination = data.poiaddress + data.poiname;
        // 救援终点经度纬度
        this.form.destination_coord[0] = data.latlng.lat;
        this.form.destination_coord[1] = data.latlng.lng;
        // 隐藏选址弹窗组件
        this.dialogVisible = false;
        // 赋值给传递的终点经度纬度
        this.locationdestination[0] = data.latlng.lat;
        this.locationdestination[1] = data.latlng.lng;
        this.lazyLoading = !this.lazyLoading;
      }
    },

    //关闭
    handleClose(){
      this.dialogVisible = false;
    },

    // 返回按钮
    returnPage(){
      this.$emit('show',false) ;
    },

    //取消按钮
    cancel(){
      this.$emit('show',false) ;
    },

    //生成订单
    save(){
      // this.form.facilitator_id = 1;
      // this.form.driver_id = 1;
      // 创建处罚订单
      if(this.name == 'addpunish'){
        this.form.pid = this.data.id
        Adddelay_join(this.form).then(res => {
          if (res.code === 200) {
            this.$message.success(res.msg);
            this.$emit('show',false);
            this.$emit('done');
          } else {
            this.$message.error(res.msg);
          }
        }).catch(e => {
          this.$message.error(e.msg);
        })
      }else{
        this.form.pid = this.data.id
        Adddelay_join(this.form).then(res => {
          if (res.code === 200) {
            this.$message.success(res.msg);
            this.$emit('show',false);
            this.$emit('done');
          } else {
            this.$message.error(res.msg);
          }
        }).catch(e => {
          this.$message.error(e.msg);
        })
      }
    },


  },
}
</script>

<style scoped lang="scss">
.card{
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
  .card_t{
    display: flex;
    align-items: center;
  }
  .card_t_icon{
    width: 5px;
    height: 19px;
    background: #ff9b05;
  }
  .card_t_text{
    font-size: 20px;
    font-weight: 700;
    color: #202033;
    margin-left: 15px;
  }
}
.card_bottom{
  margin-top: 80px;
  text-align: center;
}

.condition{
  .condition_t{
    display: flex;
    align-items: center;
  }
  .condition_t_icon{
    width: 5px;
    height: 19px;
    background: #ff9b05;
  }
  .condition_t_text{
    font-size: 20px;
    font-weight: 700;
    color: #202033;
    margin-left: 15px;
  }


  .condition_b{
    margin-top: 20px;
    .condition_b_img{
      width: 280px;
      height: 255px;
      background-image: url("../../../assets/images/home/custom/upload_bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      text-align: center;
      padding-top: 50px;
      cursor: pointer;
    }
    .condition_b_img_anniu{
      width: 94px;
      height: 94px;
    }
    .condition_b_img_text{
      font-size: 16px;
      font-weight: 500;
      color: #ff9b05;
    }

    .condition_b_l{
      width: 20%;
      float: left;
    }
    .condition_b_r{
      width: 78%;
      float: right;
      margin-left: 30px;
    }

  }
  // 解决高度坍塌
  .condition_b:after{
    content: '';
    clear: both;
    display: block;
  }


}
</style>
