<template>
  <!--关联订单 子订单-->
  <div>
    <!--关联订单-->
    <div class="ageing" style="margin-top: 20px;">
      <div class="Datalist">
        <div class="Datalist_l">
          <div class="Datalist_icon"></div>
          <div class="Datalist_text">
            关联订单
          </div>
          <div class="custom" style="margin-left: 20px">
            <el-button class="custom-button" style="background: #FF9B05 !important;border-color: #FF9B05!important;" @click="Add()">
              <!--el-icon-devops 是自己命名的-->
              <i class="el-icon-tianjia" />
              <span style="vertical-align: middle">添加</span>
            </el-button>
          </div>
        </div>
      </div>
      <!--边框里的内容-->
      <div class="ageing_b" style="padding: 20px;">
        <my-table
            ref="myTable"
            :columns="columns"
            :tableData="List"
            :headerCellStyle="headerCellStyle"
            :total="total"
            :customsFromWhere="where"
            :loading="loading"
            @select="select"
            @ChangeSize="ChangeSize"
            @currentChange="currentChange"
        >
          <!-- 不使用数据可不接收scope值 -->
          <template v-slot:operationSlot= "{ scope }">
            <el-popconfirm title="确定执行此操作吗？" @confirm="Disassociate(scope.row)">
              <el-link slot="reference" :underline="false" type="warning" @click="(scope.row)">取消关联</el-link>
            </el-popconfirm>
          </template>
        </my-table>
      </div>
    </div>


    <!--子订单-->
    <div class="ageing" style="margin-top: 20px;">
      <div class="Datalist">
        <div class="Datalist_l">
          <div class="Datalist_icon"></div>
          <div class="Datalist_text">
            子订单
          </div>
        </div>
      </div>
      <!--边框里的内容-->
      <div class="ageing_b" style="padding: 20px;">
        <my-table
            ref="myTable"
            :columns="columns1"
            :tableData="List1"
            :headerCellStyle="headerCellStyle1"
            :total="total1"
            :customsFromWhere="where1"
            :loading="loading1"
            @select="select1"
            @ChangeSize="ChangeSize1"
            @currentChange="currentChange1"
        >
        </my-table>
      </div>

    </div>

  </div>
</template>

<script>
import {Savecorrelation_cancel, Savecorrelation_existing} from "@/api/orderModule";

export default {
  props:{
    shuju:Object,
    data: Array,
    children_order:Array
  },
  data(){
    return{
      //示例图
      img:require('../../../assets/images/home/order/shilitu.png'),
      dialogImageUrl: '',
      dialogVisible: false,

      // 关联订单
      //条件
      where:{
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:false,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "订单号/报案号",
          // type: "index",
          // width: "70"
          prop: "son_odd",
          isShow: true
        },
        {
          label: "服务商",
          prop: "facilitator_name",
          isShow: true
        },
        {
          label: "服务人员",
          prop: "service_staff_name",
          isShow: true
        },
        {
          label: "手机号",
          prop: "service_staff_phone",
          isShow: true
        },
        {
          label: "订单时间",
          prop: "like_time",
          isShow: true
        },
        {
          label: "故障车牌",
          prop: "license_plate",
          isShow: true
        },
        {
          label: "操作",
          width: "200",
          slot: "operationSlot",
          isShow: true
        },
      ],

      // 子订单订单
      //条件
      where1:{
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 表格数据
      List1: [],
      // 总条目数
      total1: 0,
      //加载动画
      loading1:false,
      // 表头数据
      columns1: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "子订单号",
          // type: "index",
          // width: "70"
          prop: "order_id",
          isShow: true
        },
        {
          label: "服务商",
          prop: "facilitator_id",
          isShow: true
        },
        {
          label: "服务人员",
          prop: "buyer_phone",
          isShow: true
        },
        {
          label: "手机号",
          prop: "acceptor_nickname",
          isShow: true
        },
        {
          label: "订单时间",
          prop: "order_time",
          isShow: true
        },
        {
          label: "订单状态",
          prop: "original_state",
          isShow: true
        },
      ],


    }
  },

  mounted() {
    this.List = this.data;
    this.List1 = this.children_order;
  },

  methods:{
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    // 关联订单表格事件
    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #F7F8FA"
    },


    // 子订单表格事件
    // 当前页数改变事件
    currentChange1(val) {
      console.log("当前页数", val)
      this.where1.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select1(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize1(val){
      console.log('当前页面显示的条数',val);
      this.where1.limit = val;
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle1({ row, column, rowIndex, columnIndex }) {
      return "background: #F7F8FA"
    },


    //点击添加
    Add(){
      this.$emit('show',true);
    },

    //取消关联
    Disassociate(row){
      console.log(row)
      let where = {
        uniques: row.uniques,
        matrix_id: this.shuju.id
      }
      Savecorrelation_cancel(row.id,where).then(res => {
        if(res.code == 200){
          this.$message.success(res.msg);
          this.$emit('huoqu');
        }else {
          this.$message.error(e.msg)
        }
      }).catch(e => {
        this.$message.error(e.msg);
      })
    },

  }

}
</script>

<style lang="scss" scoped>

</style>
