<!-- 客户话术弹窗 -->
<template>
  <el-dialog
      width="40%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'客户话术':'客户话术'"
      @update:visible="updateVisible">
    <el-form
        ref="form"
        :model="form"
        label-width="100px">
      <el-row>
        <el-row>
          <div class="sijiradio">
            <div class="diyixiang" style="margin: 20px;cursor: pointer;" v-for="(item,index) in List" :key="index" >
              <div style="margin-right: 20px;display: flex;align-items: center;">
                <img v-if="index==isShow" src="@/assets/images/home/xuanzhonganniu.png" alt="">
                <img v-else src="@/assets/images/home/weixuanzhonganniu.png" alt="">
                <span style="font-size: 16px;font-weight: 500;color: #2d2f47;margin-left: 10px;">是否说出</span>
              </div>
              <div class="listactive" :class="{listactive1:index==isShow}" @click="activeItem(item,index)">
                <div>
                  {{item.content}}
                </div>
              </div>
            </div>
          </div>
        </el-row>
      </el-row>


    </el-form>


    <div slot="footer">
      <el-button
          @click="updateVisible(false)">取消
      </el-button>
      <el-button style="background: #53D1A1 !important; border-color: #53D1A1 !important; color: #FFFFFF !important;">
        拨打下游服务商电话
      </el-button>
      <el-button style="background: #5976E1 !important; border-color: #5976E1 !important; color: #FFFFFF !important;">
        车主电话
      </el-button>
      <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="save">拨打司机电话
      </el-button>
    </div>

  </el-dialog>
</template>

<script>

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({status: 1}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      isShow:0,
      List:[
        {
          content:'1. 这是一段客户困境救援话术这是一段客户困境救援话术这是一段客户困境救援话术这是一段客户困境救援话术这是一段客户困境救援话术这是一段客户困境救援话术这是一段客户困境救援话术'
        },
        {
          content:'2. 这是一段客户困境救援话术这是一段客户困境救援话术这是一段客户困境救援话术这是一段客户困境救援话术这是一段客户困境救援话术这是一段客户困境救援话术这是一段客户困境救援话术'
        },
        {
          content:'2. 这是一段客户困境救援话术这是一段客户困境救援话术这是一段客户困境救援话术这是一段客户困境救援话术这是一段客户困境救援话术这是一段客户困境救援话术这是一段客户困境救援话术'
        }
      ],

    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data)
        this.form = Object.assign({}, this.data);
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
      }
    }
  },
  mounted() {

  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.updateVisible(false);
          this.$emit('done');
          this.loading = false;
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

    //选择
    activeItem(item,index){
      console.log(item);
      console.log(index);
      this.isShow = index;
    },


  }
}
</script>

<style scoped lang="scss">
.sijilist{
  .sijilistactive{
    cursor: pointer;
    display: inline-block;
    width: 64px;
    height: 36px;
    font-size: 16px;
    font-weight: 500;
    color: #868792;
    margin: 10px;
  }
  .sijilistactive1{
    color: #FF9B05;
    border-bottom: 4px solid #FF9B05;
  }
}

.sijiradio{

}
.listactive{
  width: 100%;
  border: 1px solid #ebecf2;
  border-radius: 6px;
  padding: 20px;
  margin-top: 15px;
}
.listactive1{
  border: 1px solid #fea837;
}
.diyixiang:first-child{
  margin-top: 0 !important;
}

</style>
