<template>
  <!--订单消息模块-->
  <div>
    <el-card shadow="never">
      <div>
        <el-button class="custom-button" style="background: #FEA837 !important;border-color: #FEA837!important; color: #fff !important;">
          <!--el-icon-devops 是自己命名的-->
          <i class="el-icon-fasong" />
          <span style="vertical-align: middle">发送给接单人</span>
        </el-button>
      </div>
      <div style="text-align: right;margin-bottom: 20px;">
        <el-checkbox v-model="checked">仅显示未读消息</el-checkbox>
        <span style="font-size: 12px;font-weight: 400;color: #53d1a1; margin-left: 20px; cursor: pointer;">全部表记已读</span>
      </div>

      <div class="xiaoxilist" v-for="(item,index) in 6" :key="index">
        <div style="display: flex;align-items: center;flex-direction: row;justify-content: space-between;">
          <div>
            <span style="background: #e0fff3;border-radius: 3px;font-size: 12px;font-weight: 400;color: #53d1a1; padding: 8px 10px 8px 10px; cursor: pointer;">已读</span>
            <span style="font-size: 16px;font-weight: 500;color: #2d2f47; margin-left: 20px;">【中轩华宇】</span>
            <span style="font-size: 16px;font-weight: 500;color: #2d2f47; margin-left: 20px;">苏JH80E9</span>
            <span style="font-size: 16px;font-weight: 500;color: #2d2f47; margin-left: 20px;">吴海军</span>
            <span style="font-size: 16px;font-weight: 500;color: #2d2f47; margin-left: 20px;">15745623157 </span>
          </div>
          <div style="font-size: 12px;font-weight: 400;color: #b3b4bf;">
            2023-12-14 12:30:54
          </div>
        </div>
        <div style="font-size: 14px;font-weight: 400;color: #868792; margin-top: 10px;">
          注意客户已来电催促1次，等待时间11分钟。【邵先生13302812866客户】来电催促救援，表示无人联系客户，请跟进，谢谢！注意客户已来电催促1次，等待时间11分钟。【邵先生13302812866客户】来电催促救援，表示无人联系客户，请跟进，谢谢！
        </div>
      </div>

    </el-card>
  </div>

</template>

<script>
export default {
  data(){
    return{
      checked:true,
    }
  }
}
</script>

<style lang="scss" scoped>
.xiang{
  margin-top: 10px;
  margin-bottom: 10px;
  .xiang_l{
    width: 180px;
    display: inline-block;
    text-align: right;
  }
  .xiang_r{
    display: inline-block;
  }
}

.xiaoxilist{
  padding: 20px;
  background: #ffffff;
  border: 1px solid #e6e6e8;
  border-radius: 6px;
  margin-bottom: 20px;
}
</style>
