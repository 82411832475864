<template>
  <!--照片审核信息-->
  <div>
    <!--通话信息-->
    <div class="ageing" style="margin-top: 20px;">
      <div class="Datalist">
        <div class="Datalist_l">
          <div class="Datalist_icon"></div>
          <div class="Datalist_text">
            照片审核信息
          </div>
        </div>
      </div>

      <div>
        <!--标题模块-->
        <div style="margin-top: 10px;">
          <el-row>
            <span style="font-size: 18px;font-weight: 700;text-align: right;color: #333333;">{{type}}</span>
            <!--<span style="font-size: 14px;font-weight: 500;text-align: right;color: #333333;margin-left: 10px;">(照片数量不少于5张)</span>-->
          </el-row>
        </div>
        <!--边框里的内容-->
        <div class="ageing_b" style="padding: 20px;" v-for="(item,index) in List" :key="index">
          <!--每一项-->
          <div>
            <el-row :gutter="15">
              <!--左-->
              <el-col :span="12">
                <!--<div style="font-size: 18px;font-weight: 700;color: #050505;">2.1 到达现场：</div>-->
                <div style="font-size: 16px;font-weight: 700;color: #050505;margin-top: 20px;">{{item.sample_graph_name}}</div>
                <div style="font-size: 16px;font-weight: 400;color: #6c6c6c;margin-top: 10px;">{{item.claim}}</div>
                <div style="font-size: 14px;font-weight: 400;color: #909090;margin-top: 20px;">示例图：</div>
                <div style="width: 240px;height: 180px;background-size: 100% 100%; background-repeat: no-repeat;margin-top: 5px;" :style="{ backgroundImage: `url(${item.sample_graph_path})` }">
                </div>
              </el-col>
              <!-- 右 -->
              <el-col :span="12">
                <div style="font-size: 14px;font-weight: 400;color: #050505;">实拍图：</div>
                <div style="background: #fafafa;border: 1px solid #e9eaf2;padding: 20px;margin-top: 20px;">
                  <el-upload
                      list-type="picture-card"
                      :on-preview="handlePictureCardPreview"
                      :action="action"
                      :headers="headers"
                      :on-success="successUpload"
                      :file-list="item.photo_url"
                      :on-remove="handleRemove">
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <el-dialog :visible.sync="dialogVisible" title="照片审核详情" center>
                    <el-row :gutter="15">
                      <el-col :span="12">
                        <img width="100%" :src="dialogImageUrl" alt="">
                      </el-col>
                      <el-col :span="12">
                        <div style="display: flex;align-items: center;">
                          <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;"></span>
                          <span style="font-size: 18px;font-weight: 700;color: #3f4155;margin-left: 10px;">车架号</span>
                        </div>
                        <div style="width: 100%; height: 200px; border-radius: 6px; margin-top: 10px;">
                          <img src="@/assets/images/home/chejiahao.png" alt="" style="width: 100%;height: 100%;">
                        </div>
                        <div style="margin-top: 10px;font-size: 14px;font-weight: 400;">
                          <div class="bianju">
                            <span style="color: #868792;">照片要求：</span>
                            <span>照片要求</span>
                          </div>
                          <div class="bianju">
                            <span style="color: #868792;">服务项目：</span>
                            <span>拖车救援</span>
                          </div>
                          <div class="bianju">
                            <span style="color: #868792;">车牌号码：</span>
                            <span>鲁C578N8</span>
                          </div>
                          <div class="bianju">
                            <span style="color: #868792;">车架号码：</span>
                            <span>UDXAA126G0021011</span>
                          </div>
                          <div class="bianju">
                            <span style="color: #868792;">客户信息：</span>
                            <span>胡* 153**2630</span>
                          </div>
                          <div class="bianju">
                            <span style="color: #868792;">上传信息：</span>
                            <span>许剑朗 未知</span>
                          </div>
                          <div class="bianju">
                            <span style="color: #868792;">上传时间：</span>
                            <span>2023-06-12 11:14:53</span>
                          </div>
                          <div class="bianju">
                            <span style="color: #868792;">审核状态：</span>
                            <span style="color: #FF9B05; ">未审核</span>
                          </div>
                        </div>

                        <!--审核按钮-->
                        <div style="margin-top: 20px;display: flex;justify-content: space-between;">
                          <el-button style="background: #36DD9E!important;border-color: #36DD9E!important;color: #FFFFFF!important;">合格</el-button>
                          <el-button style="background: #36DD9E!important;border-color: #36DD9E!important;color: #FFFFFF!important;">合格，下一张</el-button>
                        </div>
                        <div style="display: flex;justify-content: space-between;margin-top: 15px;">
                          <el-button style="background: #F54E71!important;border-color: #F54E71!important;color: #FFFFFF!important;">不合格</el-button>
                          <el-button>关闭</el-button>
                        </div>
                        <div style="margin-top: 20px;display: flex;align-items: center;justify-content: space-between;flex-direction: row;flex-wrap: nowrap;">
                          <el-button style="background: #f1f1f1 !important;border-color: #dcdce0!important;">
                            <i class="el-icon-leftjiantou" />
                            <span style="vertical-align: middle">上一张</span>
                          </el-button>
                          <span>3/18</span>
                          <el-button style="background: #f1f1f1 !important;border-color: #dcdce0!important;">
                            <i class="el-icon-rightjiantou" />
                            <span style="vertical-align: middle">下一张</span>
                          </el-button>
                        </div>

                      </el-col>
                    </el-row>
                  </el-dialog>

                </div>
              </el-col>

            </el-row>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
  // 获取配置
  import setting from '@/config/setting'
  import {getCookies} from '@/utils/cookies'
  import Config from '@/config'

  export default {
    props:{
      data: Array,
      type: String
    },
    data(){
      return{
        // 上传接口
        action: setting.apiBaseURL + 'common/upload/order',
        // 上传请求头部
        headers:{
          'Authori-Zation' : 'Bearer' + ' ' + getCookies(Config.tokenStoreName)
        },
        List:[],

        //示例图
        img:require('../../../assets/images/home/order/shilitu.png'),
        dialogImageUrl: '',
        dialogVisible: false,
        fileList:[],
      }
    },

    mounted() {
      this.List = this.data;
      console.log(this.data);
      console.log(this.type);
    },

    methods:{
      handleRemove(file, fileList) {
        console.log(file, fileList);
      },
      handlePictureCardPreview(file,fileList) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
        console.log(this.fileList);
      },
      successUpload(file,fileList){
        console.log(file)
        console.log(fileList)
      },

    }

  }
</script>

<style lang="scss" scoped>
  .bianju{
    margin-bottom: 10px;
  }
</style>
